import axios from "axios";
import { useEffect, useState } from "react";

interface queryTypes {
  params: string;
  populate: string;
}

function useFetch({ params, populate }: queryTypes) {
  const API = process.env.STRAPI_API_URL;
  const [error, setError] = useState(null);
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const url = `${API}/api/${params}${params.split("?").length > 1 ? "&" : "?"}populate=${populate}`;
    params !== '' && axios
      .get(url).then((res) => { setData(res.data); })
      .catch((err) => { setError(err); })
      .finally(() => { setLoading(false); });
  }, [params]);

  return { data, loading, error };
}

export default useFetch;
