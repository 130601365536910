import React from 'react'
interface IProps {
  text: string
  isWhite: boolean
}
function Active(props: IProps) {
  const { text, isWhite } = props
  return (
    <button className={`rounded-[6px] bg-flexi ${isWhite ? 'hover:border-white hover:text-white' : 'hover:border-black hover:text-black'}
     border border-transparent hover:bg-transparent hover:text-[#000000] transition-all h-[40px] px-[20px] uppercase text-black text-sm subheading3`}>
      {text}
    </button>
  )
}

export default Active