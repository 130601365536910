import React from "react";

import { Link, navigate, Script } from "gatsby";

import footerImg from "../assets/images/footerImg.png";
import viva from "../assets/images/viva.png";
import appleDownload from "../assets/svg/socials/appleDownload.png";
import facebook from "../assets/svg/socials/facebook.svg";
import googleDownload from "../assets/svg/socials/googleDownload.png";
import instagram from "../assets/svg/socials/instagram.svg";
import useFetch from "../hooks/useFetch";

type FooterProps = {
  isHiddenInsta?: boolean;
};
function Footer(props: FooterProps) {
  const { isHiddenInsta } = props;
  const { data } = useFetch({
    params: "footer",
    populate: "brands.logo,socials",
  });
  return (
    <>
      <div className="bg-turf ">
        <Script src="https://apps.elfsight.com/p/platform.js" defer />
        {!isHiddenInsta && (
          <>
            <div className="lg:w-[1300px] w-full  mx-auto footer pt-[37px] overflow-hidden remove-tag relative">
              <div className="elfsight-app-eb453301-4c79-4012-a996-25e430da28d5"></div>
            </div>
            <div className="w-full h-[1px] mt-[42px] bg-white"></div>
          </>
        )}
        <div className="lg:pt-[37px] pt-5 lg:px-[70px] lg:pb-[13px] grid grid-cols-2 lg:grid-cols-12 gap-[20px] w-full max-w-[1440px] mx-auto">
          <img
            alt="footer image"
            className="lg:col-span-2 h-[82px] order-last lg:order-none lg:place-self-start place-self-end lg:pr-0 pr-6"
            src={footerImg}
          />
          <div className="text-white lg:pl-0 pl-6 flex flex-col gap-[12px] lg:col-span-2">
            <p className="subheading">explore</p>
            <p
              onClick={() => navigate("/studios")}
              className="body hover:text-apricot duration-300 transition-all cursor-pointer"
            >
              Studios
            </p>
            <p
              onClick={() => navigate("/classes")}
              className="body hover:text-apricot duration-300 transition-all cursor-pointer"
            >
              Classes
            </p>
            <p
              onClick={() => navigate("/timetable")}
              className="body hover:text-apricot duration-300 transition-all cursor-pointer"
            >
              Timetable
            </p>
            <p
              className="body hover:text-apricot duration-300 transition-all cursor-pointer"
              onClick={() => navigate("/about")}
            >
              About
            </p>
            {/* <p
              className="body hover:text-apricot duration-300 transition-all cursor-pointer"
              onClick={() => navigate("/blogs")}
            >
              Blog
            </p> */}
            {/* <p
              onClick={() => navigate("/own-a-studio")}
              className="body hover:text-apricot duration-300 transition-all cursor-pointer"
            >
              Own a studio
            </p> */}
          </div>
          <div className="text-white  flex justify-center lg:col-span-2">
            <div className="text-white flex flex-col gap-[12px] ">
              <p className="subheading ">support</p>
              <p
                onClick={() => navigate("/contact")}
                className="body hover:text-apricot duration-300 transition-all cursor-pointer"
              >
                Contact us
              </p>
              <p
                onClick={() => navigate("/faq")}
                className="body hover:text-apricot duration-300 transition-all cursor-pointer"
              >
                FAQ
              </p>
              <p
                className="body hover:text-apricot duration-300 transition-all cursor-pointer"
                onClick={() => navigate("/legal")}
              >
                Legal
              </p>
              {/* <p className="body hover:text-apricot duration-300 transition-all cursor-pointer">
                Help Centre
              </p> */}
            </div>
          </div>
          <div className="text-white lg:pl-0 pl-6 flex flex-col lg:pb-0 pb-3 items-start gap-[50px] lg:col-span-3 lg:items-center">
            <div className="h-1/2 flex flex-col gap-[12px]">
              <p className="subheading ">join us </p>

              <p
                onClick={() => navigate("/memberships")}
                className="body hover:text-apricot duration-300 transition-all cursor-pointer"
              >
                Memberships
              </p>
              <p
                className="body hover:text-apricot duration-300 transition-all cursor-pointer"
                onClick={() => navigate("/work-with-us")}
              >
                Work with us
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row  pb-6 lg:pb-10 lg:pt-0 pt-6 lg:border-0 border-y border-white mt-4 lg:mt-0 lg:justify-between lg:items-center justify-start items-start  w-full max-w-[1296px] mx-auto">
          <div className="flex lg:px-0 px-6 items-center justify-start gap-6 cursor-pointer">
            <img
              alt="facebook"
              onClick={() =>
                window.open(data?.data?.attributes?.socials?.[0]?.url)
              }
              src={facebook}
            />
            <img
              alt="instagram"
              onClick={() =>
                window.open(data?.data?.attributes?.socials?.[1]?.url)
              }
              src={instagram}
            />
            {/* <img src={linkedin} />
            <img src={twitter} /> */}
          </div>
          <div className="flex  items-center lg:px-0 px-5 lg:mt-0 mt-3 justify-start   cursor-pointer">
            <div
              onClick={() => window.open(data?.data?.attributes?.appStoreUrl)}
            >
              <img
                alt="apple download"
                className="w-[157px] h-[51px]"
                src={appleDownload}
              />
            </div>
            <div
              onClick={() => window.open(data?.data?.attributes?.playStoreUrl)}
            >
              <img
                alt="google download"
                className="w-[155px] h-[67px]"
                src={googleDownload}
              />
            </div>
          </div>
        </div>
        <div className=" hidden ">
          <div className="w-[62.5%] flex border-y border-white pl-[25px] cursor-pointer ">
            <div
              onClick={() =>
                window.open(data?.data?.attributes?.brands?.[0]?.url)
              }
              className="w-1/3  py-[30px] px-[45px]"
            >
              <img
                alt="image"
                src={
                  data?.data?.attributes?.brands?.[0]?.logo?.data?.attributes
                    ?.url
                }
              />
            </div>
            <div
              onClick={() =>
                window.open(data?.data?.attributes?.brands?.[0]?.url)
              }
              className="w-1/3 flex items-center  border-x border-white px-[45px]"
            >
              <img
                alt="appstore"
                src={
                  data?.data?.attributes?.brands?.[1]?.logo?.data?.attributes
                    ?.url
                }
              />
            </div>
            <div
              onClick={() => window.open(data?.data?.brands?.[0]?.url)}
              className="w-1/3 flex items-center px-[45px] "
            >
              <img
                alt="ch play"
                src={
                  data?.data?.attributes?.brands?.[2]?.logo?.data?.attributes
                    ?.url
                }
              />
            </div>
          </div>
          <div className=" w-[37.5%] border-y py-[30px] border-l px-[60px] border-white body-sm text-white ">
            {data?.data?.attributes?.customMetadata?.brandsDesc}
          </div>
        </div>
        <div className="body-sm text-white  w-full border-t-white border-t ">
          <div className="flex justify-between items-center  px-6 lg:px-[70px] py-6 max-w-[1440px] mx-auto w-full">
            <div className="body-sm text-white ">
              {data?.data?.attributes?.copyrightText}
            </div>
            <div className="feature cursor-pointer text-white">
              <Link to="/">
                <img className="w-[75px] h-[34px]" src={viva} alt="logo" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
