import React, { useEffect, useState } from 'react';
function CheckboxActive(props: any) {
  const { isDashboard, isChecked } = props;
  const [isAccepted, setIsAccepted] = useState(false);

  useEffect(() => {
    setIsAccepted(isChecked)
  }, [])

  return (
    <button
      onClick={() => setIsAccepted(!isAccepted)}
      style={isAccepted ? { border: '1px solid rgba(0, 0, 0, 0.2)' } : { border: '1px solid rgba(0, 0, 0, 0.2)', backgroundColor: 'transparent' }}
      className={`text-white font-bold rounded h-[24px] w-[24px] flex justify-center items-center`}
    >
      {isAccepted ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
          <path d="M1 4.23077L4.5 8L11 1" stroke={isDashboard ? 'black' : '#FFAA5C'} strokeWidth="1.88498" />
        </svg>
      ) : (
        ''
      )}
    </button>
  );
}

export default CheckboxActive;
