import React from 'react';

import TextAnimation from '../own-a-studio/TextAnimation';

function Banner(props) {
    const { data } = props
    return (
        <div> <div className="relative">
            {data?.data?.attributes?.topBanner?.background?.data?.attributes?.url?.slice(-3) == 'mp4'
                ? <video className=" w-[100vw]  lg:h-[688px] h-[518px] object-cover  overflow-hidden"
                    src={data?.data?.attributes?.topBanner?.background?.data?.attributes?.url}></video>
                : <>
                    <img src={data?.data?.attributes?.topBanner?.background?.data?.attributes?.url}
                        // className=" w-[100vw] h-[688px] md:block hidden object-cover  overflow-hidden" 
                        className=" w-[100vw] object-cover object-bottom md:block hidden  overflow-hidden"
                        />
                    <img src={data?.data?.attributes?.topBanner?.mobileImage?.data?.attributes?.url}
                        // className=" w-[100vw] h-[518px] object-cover md:hidden block  overflow-hidden"
                        className=" w-[100vw] object-cover object-bottom md:hidden block overflow-hidden"
                        />

                </>
            }
            <p className="heading text-white absolute top-1/2 lg:w-auto w-[90%] left-1/2 -translate-x-1/2 -translate-y-1/2 text-center ">
                {data?.data?.attributes?.topBanner?.title}
            </p>
        </div>
            <TextAnimation />
        </div>
    )
}

export default Banner