import logoText from "../src/assets/svg/logoText.svg";

export const navBar = [
  {
    type: "link",
    text: "studios",
    link: "/studios",
  },
  {
    type: "link",
    text: "classes",
    link: "/classes",
  },
  {
    type: "link",
    text: "timetable",
    link: "/timetable",
  },
  {
    type: "link",
    text: "memberships",
    link: "/memberships",
  },
  {
    type: "linkLogo",
    text: "logo",
    link: "/",
    img: logoText,
  },
  {
    type: "link",
    text: "about",
    link: "/about",
  },
  // {
  //   type: "link",
  //   text: "own a studio",
  //   link: "/own-a-studio",
  // },
  // ,
  {
    type: "linkButton",
    text: "log in",
    link: "/login",
  },
  {
    type: "linkButton",
    text: "join now",
    link: "/register",
  },
];

export const sideBar = [
  {
    name: "classes",
    path: "classes",
  },
  // {
  //   name: "own a studio",
  //   path: "own-a-studio",
  // },
  {
    name: "about",
    path: "about",
  },
  {
    name: "memberships",
    path: "memberships",
  },
  {
    name: "timetable",
    path: "timetable",
  },
  {
    name: "find a studio",
    path: "studios",
  },
];
