import React, { memo } from 'react'
import CheckboxActive from '../buttons/CheckboxActive'
import Active from '../buttons/Active'
interface IProps {
    data: string[]
    initial: string[]
    onClick: () => void
    setData: React.Dispatch<React.SetStateAction<string[]>>;
}
function SelectCheckbox(props: IProps) {
    const { data, setData, initial, onClick } = props

    const handleChecked = (current: string) => {
        setData(prev => {
            const isChecked = data.some((item: string) => item === current);
            return isChecked ? data?.filter((item: string) => item !== current) : [...prev, current];
        });
    }

    return (
        <div style={{ border: '1px solid rgba(0, 0, 0, 0.2' }} className='w-[384px] bg-[#FEFEFF] pt-3 px-3 pb-5 rounded-bl-md rounded-t-none   rounded-br-md '>
            <ul className='flex flex-col gap-3'>
                {initial?.map((item: string, idx: number) => (
                    <li key={idx} className='flex items-center justify-between'>
                        <span className='heading3'>{item}</span>
                        <div onClick={() => handleChecked(item)}>
                            <CheckboxActive isChecked={data.includes(item) ? true : false} />
                        </div>
                    </li>
                ))}
            </ul>
            <div onClick={() => onClick()} className='mt-[25px] mx-auto w-full flex justify-center'><Active text='apply' /></div>
        </div>
    )
}

export default memo(SelectCheckbox)