import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout/Layout'
import HeroBanner from '../../components/HomePage/HeroBanner'
import TimeRebalance from '../../components/studio/Time-Rebalance'
import Flexible from '../../components/studio/Flexible'
import MeetOurTeam from '../../components/studio/Meet-Our-Team'
import { useLocation } from '@reach/router';
import ErrorBoundary from '../../utils/ErrorBoundary'
import useFetch from '../../hooks/useFetch'
function StudioDetail() {
    let id = useLocation()
    const API = process.env.STRAPI_API_URL;
    const url = `${API}/api/studios/${id?.state?.id}?populate=*,membershipPlans.image,membershipPlans.features,trainners.avatar,gallery.image,background,trainners.qualifications,membership-plans.image,bottomBanner.image,studioDescription.image,topBanner.image,openTime`;

    const { data: allStudios } = useFetch({ params: 'studios', populate: 'membershipPlans.image,membershipPlans.features,trainners.avatar,gallery.image,background,trainners.qualifications,membership-plans.image,bottomBanner.image,studioDescription.image,topBanner.image,openTime' })
    const [studioTricgger, setStudioTrigger] = useState()

    let name = id?.pathname?.split("/")
    let location = name[name.length - 1];

    const [data, setData] = useState<[]>([])

    useEffect(() => {
        setStudioTrigger(allStudios?.data?.find(item => item?.attributes?.title?.toLowerCase().replace(" ", "-") == location))
    }, [allStudios])

    useEffect(() => {
        fetch(url).then((res) => res.json().then((data) => id?.state?.id !== undefined ? setData(data?.data) : setData(studioTricgger)))
    }, [allStudios, studioTricgger])


    return (
        <ErrorBoundary>
            <Layout>
                <HeroBanner
                    img={data?.attributes?.topBanner?.image?.data?.attributes?.url}
                    title={data?.attributes?.topBanner?.title}
                    bannerMobile={data?.attributes?.topBanner?.image?.data?.attributes?.url}
                />
                <TimeRebalance data={data} />
                <Flexible data={data} />
                {/* <MeetOurTeam data={data} /> */}
            </Layout>
        </ErrorBoundary>
    )
}

export default StudioDetail