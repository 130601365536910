import React, { useState, useEffect } from "react";

import { Script } from "gatsby";
function TimeTableFilter() {

  // Location Selected
  const [state, setState] = useState()

  useEffect(() => {
    let currentState = JSON.parse(localStorage.getItem('studio'))
    if (typeof currentState == 'object') {
      setState(currentState?.attributes?.state?.data?.attributes?.Name)
    } else {
      setState(currentState)
    }
  }, [localStorage.getItem('studio')])



  return (
    <div className="">
      {/* Modal Login */}
      <Script src="https://widgets.mindbodyonline.com/javascripts/healcode.js" type="text/javascript"></Script>
      <Script src="https://cdnjs.cloudflare.com/ajax/libs/webcomponentsjs/2.2.10/webcomponents-bundle.js"></Script>
      <div className="max-w-[1300px]  mx-auto">
        <healcode-widget
          data-type="schedules"
          data-widget-partner="object"
          data-widget-id={
            state == 'VIC' && '8319445028f8' ||
            state == 'NSW' && 'f4179031bfd1' ||
            state == 'ACT' && 'c81888356062' ||
            state == 'QLD' && 'b9555949d14'
          }
          data-widget-version="1">
        </healcode-widget>

      </div>

    </div>
  );
}

export default TimeTableFilter;
