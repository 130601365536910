import React, { useContext, useEffect } from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import ChangeLocation from "../Location/ChangeLocation";
import CreateProfile from "../Modal/CreateProfile";
import { RegisterContext } from "../../context/RegisterContext";
import ZendeskWidget from "../ZendeskWidget";

function Layout(props: any) {
  const register = useContext(RegisterContext).register;

  // Hide body scrollbar + overlay when MBO loaded
  useEffect(() => {
    register
      ? document?.body?.classList?.add("hide-scroll-mbo")
      : document?.body?.classList?.remove("hide-scroll-mbo");
  }, [register]);
  return (
    <div id="Layout" className="w-full  relative mx-auto">
      {/* <ChangeLocation /> */}
      <Navbar />
      <div id="spacer" className="h-[73px] show-bar"></div>
      {props.children}

      <Footer />
      {register && <CreateProfile />}
      {/* <ZendeskWidget /> */}
    </div>
  );
}

export default Layout;
