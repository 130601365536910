import React from "react";
import Active from "../../common/buttons/Active";
function YogaExp(props) {
  const { data } = props
  return (
    <div className="w-full bg-lemonadeSecondary">
      <div className="flex lg:flex-row flex-col justify-between lg:w-[1100px] w-[90%] items-center py-[40px]  mx-auto">
        <div className=" lg:py-[56px] py-[10px] flex flex-col justify-between">
          <p className="heading lg:w-[439px] w-full">
            {data?.data?.attributes?.banner3?.title}
          </p>
          <p className="body my-[48px] lg:w-[439px] w-full">
            {data?.data?.attributes?.banner3?.description}
          </p>
          <div className="block">
            {/* <Active text="go to section" /> */}
          </div>
        </div>
        <div className="bg-lemonadeSecondary w-full">
          <div className="h-full lg:block max-h-[625px]">
            <div className="relative lg:max-w-[621px] max-w-[350px] lg:-left-0 -left-12  z-20 overflow-hidden">
              <svg className="lg:w-[622px]  w-[350px] lg:h-[625px] h-[350.8px]" viewBox="0 0 622 625" fill="#FFEDB7" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 0C6.68629 0 4 2.68628 4 5.99999V619C4 622.314 6.68631 625 10 625H616C619.314 625 622 622.314 622 619V502.721C621.999 505.013 621.789 507.329 621.335 509.673C616.28 535.752 586.592 545.004 563.49 542.52C549.06 540.97 535.427 536.147 521.796 531.325C508.054 526.463 494.313 521.602 479.759 520.096C460.548 518.101 441.284 521.966 421.901 525.856C401.846 529.88 381.664 533.93 361.284 531.538C348.124 529.996 334.982 526.721 321.819 523.44C294.964 516.748 268.026 510.035 240.693 517.975C231.404 520.676 221.848 524.778 212.171 528.932C182.792 541.542 152.294 554.633 124.767 530.45C105.522 513.538 113.877 488.505 121.815 468.189C136.673 430.207 137.439 396.969 130.295 357.341C123.012 316.917 123.667 274.386 130.379 233.948C134.11 211.427 135.043 195.352 128.861 172.943C127.513 168.065 125.993 163.225 124.474 158.388C119.731 143.285 114.996 128.208 115.534 112.091C116.258 90.7554 129.682 68.2481 150.667 64.5643C165.711 61.9255 179.79 69.0484 193.812 76.1425C198.623 78.5765 203.427 81.0072 208.262 83.039C247.671 99.6075 278.052 93.6071 312.369 86.8294C331.491 83.053 351.834 79.0352 375.641 78.5459C405.843 77.9258 425.911 83.3455 442.671 87.872C460.608 92.7163 474.758 96.5377 493.489 90.8391C503.216 87.8792 512.608 83.4975 521.973 79.1288C540.642 70.42 559.201 61.7626 580.075 64.5225C597.927 66.8806 613.551 81.3506 617.31 99.002C621.871 120.435 614.65 141.104 607.347 162.009C601.004 180.165 594.599 198.499 595.796 217.664C596.661 231.528 598.786 245.85 600.936 260.345C603.457 277.346 606.015 294.585 606.616 311.6H606.63C606.853 317.921 606.811 324.201 606.393 330.438C605.531 343.289 602.862 356.007 600.203 368.675C594.744 394.684 589.329 420.482 599.695 446.784C602.2 453.14 605.561 459.373 608.913 465.59C615.47 477.75 621.993 489.846 622 502.677V6C622 2.68629 619.314 0 616 0H10Z" fill="" />
              </svg>
              <img className="-z-10 absolute lg:top-[64px] top-[-40px] lg:w-[622px]   w-[349px] lg:h-[625px] h-[350.8px] object-cover lg:left-[112px] left-0" src={data?.data?.attributes?.banner3?.background?.data?.attributes?.url} />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default YogaExp;
