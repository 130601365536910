import React, { useState, useEffect, useContext } from 'react'
import SelectCheckbox from '../../common/dropdowns/SelectCheckbox';
import location from '../../assets/svg/location.svg'
import phone from '../../assets/svg/phone.svg'
import email from '../../assets/svg/mail.svg'
import Map from '../Map';
import { Link, navigate } from 'gatsby';
import useFetch from '../../hooks/useFetch';
import { LocationContext } from '../../context/LocationContext';
import { calculateDistance } from '../../utils/GetDistance'
function Studios() {
    const [sort, setSort] = useState<string[]>([])
    const [search, setSearch] = useState<string>('')
    const [postcode, setPostcode] = useState<string>('')
    const [locations, setLocations] = useState<string[]>([])
    const { data } = useFetch({ params: 'studios', populate: 'background,studio_features,state' })

    const [isMap, setIsMap] = useState<boolean>(false)
    const [actions, setActions] = useState<object>({ filter: false, search: false, sort: false, });

    const [studioData, setStudioData] = useState<object[]>([])

    // Context
    const nearYou = useContext(LocationContext).nearYou
    const myPosition = useContext(LocationContext).currentPos
    const setModal = useContext(LocationContext).setModal


    // Filter + Search in this one
    useEffect(() => {
        if (/^\d+$/.test(search)) {
            const exists = data?.data?.some(obj => obj.attributes?.postCode?.toLowerCase()?.includes(search.toLocaleLowerCase()));
            if (exists) {
                setStudioData(data?.data.filter((item) => locations.includes(item?.attributes?.state?.data?.attributes?.Name) && item?.attributes?.postCode?.includes(search)))
            } else {
                setStudioData(data?.data?.filter((item) => item?.attributes?.fullAddress?.toLowerCase()?.includes(search?.toLowerCase()) && locations?.includes(item?.attributes?.state?.data?.attributes?.Name)))
            }
        } else {
            const exists = data?.data?.some(obj => obj.attributes?.title?.toLowerCase()?.includes(search.toLocaleLowerCase()));
            if (exists) {
                setStudioData(data?.data?.filter((item) => item?.attributes?.title?.toLowerCase()?.includes(search?.toLowerCase()) && locations?.includes(item?.attributes?.state?.data?.attributes?.Name)))
            } else {
                setStudioData(data?.data?.filter((item) => item?.attributes?.fullAddress?.toLowerCase()?.includes(search?.toLowerCase()) && locations?.includes(item?.attributes?.state?.data?.attributes?.Name)))
            }
        }
    }, [actions.filter, search])
    // Sort
    const handleSorting = () => {
        setStudioData(studioData.sort((a, b) => {
            if (sort.includes('A-Z')) {
                return a?.attributes?.title.localeCompare(b?.attributes?.title)
            } if (sort.includes('Z-A')) {
                return b?.attributes?.title.localeCompare(a?.attributes?.title)
            }
        }))
    }
    // Get Studio Data
    useEffect(() => { locations.length == 0 && setLocations(['ACT', 'QLD', 'NSW', 'VIC']) }, [locations])

    useEffect(() => { setStudioData(data?.data) }, [data])


    return (
        <div className='desktop:w-[1300px] w-[93%] mx-auto lg:pt-[72px] pt-[40px] lg:pb-[105px] pb-[50px]'>
            <div className='lg:grid-cols-3  grid-cols-1 grid  w-full gap-x-[17px] lg:gap-y-0 gap-y-3 mx-auto '>
                {/* <div style={{ border: '1px solid rgba(0,0,0,0.2)' }} className='rounded-[6px] h-[44px] w-full p-4 flex items-center gap-7'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M16.5 16.5L21 21" stroke="#2A353D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10Z" stroke="#2A353D" strokeWidth="1.5" strokeLinejoin="round" />
                    </svg>
                    <input onChange={(e) => setSearch(e.target.value)} type="text" placeholder='City, suburb or postcode' className='border-none placeholder:text-black text-black outline-none heading3' />
                </div> */}
                <div
                    style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                    className='pl-3  relative rounded-[6px] w-full h-[44px] flex cursor-pointer items-center justify-between pr-4'>
                    <div className='w-full  flex items-center justify-between'
                        onClick={() => setActions({ ...actions, filter: !actions.filter })}>
                        <span className='heading3'>{locations.join(', ')}</span>
                        <div
                            className='w-[100px] h-full flex items-center justify-end'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="8" viewBox="0 0 15 8" fill="none">
                                <path d="M1.66797 1L6.96086 6.29289C7.2942 6.62623 7.46086 6.79289 7.66797 6.79289C7.87508 6.79289 8.04174 6.62623 8.37508 6.29289L13.668 1" stroke="#2A353D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                    {actions.filter == true && (<div className='absolute left-0 z-40 top-[60px]' >
                        <SelectCheckbox
                            onClick={() => setActions({ ...actions, filter: false })}
                            initial={['ACT', 'QLD', 'NSW', 'VIC']} data={locations} setData={setLocations} />
                    </div>)}
                </div>
                <div className='flex w-full gap-5'>
                    <div
                        style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                        className='pl-3 w-full z-20 rounded-[6px] cursor-pointer  relative h-[44px] flex items-center justify-between pr-4'>
                        <div
                            onClick={() => setActions({ ...actions, sort: !actions.sort })}
                            className='flex items-center w-full justify-between'>
                            <span className='heading3 w-full'>{sort?.length > 0 ? sort.join(', ') : 'Sort by'}</span>
                            <div
                                className='w-[100px] flex items-center justify-end'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="8" viewBox="0 0 15 8" fill="none">
                                    <path d="M1.66797 1L6.96086 6.29289C7.2942 6.62623 7.46086 6.79289 7.66797 6.79289C7.87508 6.79289 8.04174 6.62623 8.37508 6.29289L13.668 1" stroke="#2A353D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                        {actions.sort == true && (<div className='absolute left-0 top-[61px]'>
                            <SelectCheckbox onClick={() => { setActions({ ...actions, sort: false }); handleSorting() }} initial={['A-Z', 'Z-A', 'Closest to me']} data={sort} setData={setSort} /></div>)}
                    </div>
                    {/* Map Setting */}
                    <div className='flex items-end justify-end gap-[5px]'>
                        <div onClick={() => setIsMap(false)} className={` ${!isMap ? 'bg-apricot border-apricot' : 'border-[#CBCBCC] bg-white '} border flex cursor-pointer items-center justify-center w-[44px] h-[44px] rounded-[6px]`}>
                            {isMap
                                ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                                    <path d="M1 1L17 1" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1 8L17 8" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1 15L17 15" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                : <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                                    <path d="M1 1L17 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1 8L17 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1 15L17 15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            }
                        </div>
                        <div onClick={() => setIsMap(true)}
                            className={`flex items-center cursor-pointer ${isMap ? 'bg-apricot border-apricot' : 'border-[#CBCBCC] bg-white '} border justify-center w-[44px] h-[44px] rounded-[6px]`}>
                            {isMap
                                ? <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
                                    <path d="M4.25345 2.19584L3.02558 2.90813C2.03739 3.48137 1.54329 3.768 1.27164 4.24483C1 4.72165 1 5.30233 1 6.46368V14.6283C1 16.1542 1 16.9172 1.34226 17.3418C1.57001 17.6244 1.88916 17.8143 2.242 17.8773C2.77226 17.9719 3.42148 17.5953 4.71987 16.8421C5.60156 16.3306 6.45011 15.7994 7.50487 15.9435C7.98466 16.009 8.44231 16.2366 9.3576 16.6917L13.1715 18.588C13.9964 18.9982 14.004 19 14.9214 19H17C18.8856 19 19.8284 19 20.4142 18.4013C21 17.8026 21 16.8389 21 14.9117V8.17148C21 6.24423 21 5.2806 20.4142 4.68188C19.8284 4.08316 18.8856 4.08316 17 4.08316H14.9214C14.004 4.08316 13.9964 4.08139 13.1715 3.6712L9.83987 2.01463C8.44884 1.32297 7.75332 0.977138 7.01238 1.00117C6.27143 1.02521 5.59877 1.41542 4.25345 2.19584Z" stroke="#FEFEFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7 1L7 15.5" stroke="#FEFEFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14 4.5L14 18.5" stroke="#FEFEFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                : <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
                                    <path d="M4.25345 2.19584L3.02558 2.90813C2.03739 3.48137 1.54329 3.768 1.27164 4.24483C1 4.72165 1 5.30233 1 6.46368V14.6283C1 16.1542 1 16.9172 1.34226 17.3418C1.57001 17.6244 1.88916 17.8143 2.242 17.8773C2.77226 17.9719 3.42148 17.5953 4.71987 16.8421C5.60156 16.3306 6.45011 15.7994 7.50487 15.9435C7.98466 16.009 8.44231 16.2366 9.3576 16.6917L13.1715 18.588C13.9964 18.9982 14.004 19 14.9214 19H17C18.8856 19 19.8284 19 20.4142 18.4013C21 17.8026 21 16.8389 21 14.9117V8.17148C21 6.24423 21 5.2806 20.4142 4.68188C19.8284 4.08316 18.8856 4.08316 17 4.08316H14.9214C14.004 4.08316 13.9964 4.08139 13.1715 3.6712L9.83987 2.01463C8.44884 1.32297 7.75332 0.977138 7.01238 1.00117C6.27143 1.02521 5.59877 1.41542 4.25345 2.19584Z"
                                        stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7 1L7 15.5" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14 4.5L14 18.5" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Studios + Map */}
            <div className={`${isMap && 'desktop:mt-[67px] mt-[40px]'} flex items-center justify-between gap-8 `}>
                {/* Studios */}
                <div className={`pr-2 lg:flex hidden  overflow-y-scroll horizontalScroll-map gap-y-6  flex-col ${!isMap ? 'opacity-0 h-0' : 'opacity-100  h-[700px]'} `}>
                    {studioData?.map((item, idx) => (
                        <div key={idx}>
                            <div className='p-6 rounded-[6px] gap-5 bg-lilac flex items-center justify-between'>
                                <div className='w-[280px] h-[289px] rounded-[6px] overflow-hidden'>
                                    <img src={item?.attributes?.background?.data?.attributes?.url} className='w-full h-full object-cover' />
                                </div>
                                <div className='w-[280px]'>
                                    <h2 className='subheading3 text-black' style={{ fontSize: '20px' }}>{item?.attributes?.title}</h2>
                                    {/* {myPosition?.lat !== undefined && (
                                            <span className='heading3 text-black'>
                                                {calculateDistance(
                                                    myPosition?.lat,
                                                    myPosition?.lng,
                                                    item?.attributes?.location?.coordinates[1],
                                                    item?.attributes?.location?.coordinates[0])} km
                                            </span>
                                        )} */}
                                    <div onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${item?.attributes?.location?.coordinates?.[1]},${item?.attributes?.location?.coordinates?.[0]}`, '_blank')} className='flex gap-[10px] my-[18px] items-center '>
                                        <img className='w-[15px]' src={location} />
                                        <p className='heading3 cursor-pointer hover:underline text-black'>{item?.attributes?.fullAddress}</p>
                                    </div>
                                    <div className='flex flex-col gap-[13px]'>
                                        {item?.attributes?.studio_features?.data?.sort((a, b) => a?.attributes?.priority - b?.attributes?.priority)?.map((fe, idx) => (
                                            <div key={idx} className='flex pl-1 items-center gap-[13px]'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                                                    <path d="M1 13L6.29289 7.70711C6.62623 7.37377 6.79289 7.20711 6.79289 7C6.79289 6.79289 6.62623 6.62623 6.29289 6.29289L1 1" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <span className='heading3 text-black'>{fe?.attributes?.title}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='mt-[25px]'>
                                        {/* <Link state={{ id: item?.id }} to={`/studio/${item?.attributes?.title?.toLowerCase().replace(" ", "-")}`}> */}
                                        <button onClick={() => localStorage.getItem('studio') !== null
                                            ? navigate(`/studio/${item?.attributes?.title?.toLowerCase().replace(" ", "-")}`, { state: { id: item?.id }, })
                                            : setModal(true)} className='h-[43px] lg:w-[247px] w-full rounded-[6px] bg-flexi uppercase subheading3'>go to studio</button>
                                        {/* </Link> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
                {/* Map */}
                <div className={`lg:w-1/2 w-full   ${!isMap ? 'opacity-0 h-0' : 'opacity-100 h-[450px] lg:h-[700px]'} `}>
                    <Map />
                </div>
            </div>
            <div className={` ${isMap ? 'opacity-0 h-0' : 'opacity-100  desktop:mt-[67px] mt-[40px]'}   gap-y-6 flex flex-col`}>
                {studioData?.map((item, idx) => (
                    <div key={idx} className='lg:p-6 p-4 rounded-[6px] lg:flex-row flex-col lg:gap-8 gap-6 bg-lilac flex items-center '>
                        <div className='lg:min-w-[556px] min-w-full lg:h-[225px] h-[180px] rounded-[6px] overflow-hidden'>
                            <img src={item?.attributes?.background?.data?.attributes?.url} className='w-full h-full object-cover' />
                        </div>
                        <div className='flex items-start desktop:flex-row  flex-col justify-between w-full'>
                            <div className='flex flex-col  items-start justify-start '>
                                <div>
                                    <h2 className='subheading3 text-black uppercase' style={{ fontSize: '20px' }}>{item?.attributes?.title}</h2>
                                    {/* {myPosition?.lat !== null && (
                                            <span className='heading3 text-black'>
                                                {calculateDistance(
                                                    myPosition?.lat,
                                                    myPosition?.lng,
                                                    item?.attributes?.location?.coordinates[1],
                                                    item?.attributes?.location?.coordinates[0])} km
                                            </span>
                                        )} */}
                                </div>
                                <div
                                    onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${item?.attributes?.location?.coordinates?.[1]},${item?.attributes?.location?.coordinates?.[0]}`, '_blank')}
                                    className='flex gap-4 desktop:mt-[43px] cursor-pointer mt-4 items-center justify-start'>
                                    <div className='xxx:w-auto w-[30px] '>
                                        <img src={location} />
                                    </div>
                                    <p className='heading3 text-black hover:underline lg:w-[250px] w-full'>{item?.attributes?.fullAddress}</p>
                                </div>
                                <div className='desktop:flex hover:underline  cursor-pointer hidden gap-4 my-[13px] items-center justify-start'>
                                    <img src={phone} />
                                    <a href={`tel:${item?.attributes?.phoneNumber}`}>
                                        <p className='heading3 '>{item?.attributes?.phoneNumber}</p>
                                    </a>
                                </div>
                                <a href={`mailto:${item?.attributes?.email}?subject=Email subject&body=${item?.attributes?.emailBody}`}>
                                    <div
                                        className='desktop:flex cursor-pointer hidden gap-4   items-center justify-start'>
                                        <img src={email} />
                                        <p className='heading3 hover:underline lg:w-[250px] w-full text-black  '>{item?.attributes?.email}</p>
                                    </div>
                                </a>
                            </div>
                            <div className='flex xxx:mt-0 mt-3 flex-col gap-[13px] relative  lg:h-[225px] h-auto pr-10'>
                                <div className='flex  items-center flex-col gap-[13px] '>
                                    {item?.attributes?.studio_features?.data?.map((fe, idx) => (
                                        <div key={idx} className='flex items-center gap-4  w-full'>
                                            <div className='xxx:w-auto w-[30px] pl-1 '>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                                                    <path d="M1 13L6.29289 7.70711C6.62623 7.37377 6.79289 7.20711 6.79289 7C6.79289 6.79289 6.62623 6.62623 6.29289 6.29289L1 1" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            <span className='heading3 text-black lg:w-[198px] w-full'>{fe?.attributes?.title}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className='flex lg:absolute  lg:justify-end justify-start lg:mt-0 mt-4 bottom-0 right-0 z-20'>
                                    {/* <Link state={{ id: item?.id }} to={`/studio/${item?.attributes?.title?.toLowerCase().replace(" ", "-")}`}> */}
                                    <button onClick={() => localStorage.getItem('studio') !== null
                                        ? navigate(`/studio/${item?.attributes?.title?.toLowerCase().replace(" ", "-")}`, { state: { id: item?.id }, })
                                        : setModal(true)} className='h-[43px] w-[247px]  transition-all rounded-[6px] bg-flexi border border-flexi hover:bg-transparent hover:border-black uppercase subheading3'>go to studio</button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div >
    )
}

export default Studios