import React from 'react'
import Layout from '../components/layout/Layout'
import Banner from '../components/classes/Banner'
import Clubs from '../components/classes/Clubs'
import useFetch from '../hooks/useFetch'
import ErrorBoundary from '../utils/ErrorBoundary'
function classes() {
    const { data } = useFetch({ params: 'classes-page', populate: 'topBanner.background,topBanner.mobileImage,membershipBanner.background,freePassBanner.background,introduce' })
    return (
        <ErrorBoundary>
            <Layout>
                <Banner data={data} />
                <Clubs data={data} />
            </Layout>
        </ErrorBoundary>
    )
}

export default classes