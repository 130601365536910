import React from 'react';

import Layout from '../components/layout/Layout';
import Banner from '../components/Membership/Banner';
import Flexible from '../components/Membership/Flexible';
import Plans from '../components/Membership/Plans';
import useFetch from '../hooks/useFetch';
import ErrorBoundary from '../utils/ErrorBoundary';

function membership() {
    const { data } = useFetch({ params: 'membership-page', populate: 'banner_1.background,banner_1.mobileImage,banner_2.background,banner_3.background,free_pass.background,introduce' })

    return (
        <ErrorBoundary>
            <Layout>
                <Banner data={data} />
                <Plans data={data} />
                <Flexible
                    isShared={true}
                    title={data?.data?.attributes?.banner_2?.title}
                    data={data}
                    flexBackground='#C5C5FB'
                    backgroundFreePass={data?.data?.attributes?.free_pass?.background?.data?.attributes?.url}
                    freePassTitle={data?.data?.attributes?.free_pass?.title}
                    freePassDesc={data?.data?.attributes?.free_pass?.description}
                    background={data?.data?.attributes?.banner_2?.background?.data?.attributes?.url}
                    description={data?.data?.attributes?.banner_2?.customMetadata}
                    sharedTitle={data?.data?.attributes?.banner_3?.title}
                    sharedDesc={data?.data?.attributes?.banner_3?.description}
                />
            </Layout>
        </ErrorBoundary>
    )
}

export default membership