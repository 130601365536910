import React from "react";
import { arrowRight } from "../../assets/images/icons/arrowRight.tsx";

function ButtonNavigate(props) {
  const { isWhite } = props
  return (
    <div
      className={`buttons uppercase text-black bg-flexi hover:bg-transparent text-center transition-all px-[20px] rounded-[5px] py-[10px] tracking-[0.05em] cursor-pointer  border border-transparent 
      ${isWhite ? 'hover:border-black hover:text-black' : 'hover:border-black hover:text-black'}
      ${props.width && props.width}`}
    >
      {props.labelText}
    </div>
  );
}

export default ButtonNavigate;
