import React, {
  createContext,
  useState,
} from 'react';

const RegisterContext = createContext<any>(null)
interface iProps {
  children: React.ReactNode;
}

const StoreProvider = ({ children }: iProps) => {
  const [register, setRegister] = useState<boolean>(false)
  const [addPlan, setAddPlan] = useState<boolean>(false)
  const [gotoPlan, setGotoPlan] = useState<boolean>(false)
  const [selectedStudio,setSelectedStudio] = useState('')



  return (
    <RegisterContext.Provider value={{ register,selectedStudio,setSelectedStudio, setRegister, addPlan, setAddPlan, gotoPlan, setGotoPlan }}>
      {children}
    </RegisterContext.Provider>
  )
}

export { RegisterContext, StoreProvider };
