import React from "react";

function Succes(props) {
  const { open, setOpen, secondary, first, custom } = props;
  return (
    <>
      {custom && (
        <>
          {open && (
            <div className="lg:w-[1000px] fixed rounded-md bg-creme z-30 top-1/2 left-1/2  transform -translate-x-1/2 -translate-y-1/2 w-[80%] h-[334px] lg:p-[64px] p-[32px]">
              <h2 className="heading text-success text-center md:px-0 px-3 text-black">
                Thanks for registering – our team will be in touch
              </h2>
              <p className="mt-[36px] max-w-[697px] text-center succes-msg mx-auto text-black ">
                Keep an eye on your inbox! We'll be sending your link to join
                the challenge within the next 24 hours. If you have any
                questions or just want to say hi, hit us up anytime.
              </p>
              <button
                onClick={() => setOpen(false)}
                className="w-[92px] flex justify-center items-center md:mt-[40px] mt-5 mx-auto h-[40px] rounded-md bg-[#FFAA5C] uppercase"
              >
                Done
              </button>
              <svg
                onClick={() => setOpen(false)}
                className="absolute right-6 top-6 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M15 1L1 15"
                  stroke="#111111"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 1L15 15"
                  stroke="#111111"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </>
      )}
      {!custom && (
        <>
          {open && (
            <div className="lg:w-[1000px] fixed rounded-md bg-creme z-30 top-1/2 left-1/2  transform -translate-x-1/2 -translate-y-1/2 w-[80%] h-[334px] lg:p-[64px] p-[32px]">
              <h2 className="heading text-success text-center md:px-0 px-3 text-black">
                {first
                  ? "Thank you!"
                  : secondary
                  ? " Thanks for registering – our team will be in touch"
                  : " It's all done! We cannot wait to see you!"}
              </h2>
              <p className="mt-[36px] max-w-[697px] text-center succes-msg mx-auto text-black ">
                {first
                  ? "A member of our Rebalance team will be in touch with your shortly."
                  : secondary
                  ? " Get ready to join Australia’s leading movement brand."
                  : "Keep an eye on your inbox! We'll be sending you a link within the next 24 hours. If you have any questions , or just want to say hi, hit us up anytime."}
              </p>
              <button
                onClick={() => setOpen(false)}
                className="w-[92px] flex justify-center items-center md:mt-[40px] mt-5 mx-auto h-[40px] rounded-md bg-[#FFAA5C] uppercase"
              >
                Done
              </button>
              <svg
                onClick={() => setOpen(false)}
                className="absolute right-6 top-6 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M15 1L1 15"
                  stroke="#111111"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 1L15 15"
                  stroke="#111111"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Succes;
