import React, { useEffect, useState } from "react";

import FreePassShape from "../../assets/images/Shape/FreePassShape";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import DropdownBase from "../../common/dropdowns/DropdownBase";
import InputBase from "../../common/inputs/RebalanceInputBase";
import useFetch from "../../hooks/useFetch";
import { FreePassToStrapiAPI, GetFreePassAPI } from "../../utils/FetchData";
import Succes from "../Modal/Succes";

function FreePass(props) {
  const { desc, img, color, isWhite, background } = props;
  const [status, setStatus] = useState<boolean>(false);
  const { data: locations } = useFetch({
    params: "studios",
    populate: "state",
  });

  const [userInfo, setUserInfo] = useState({
    venue_id: "1234",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    interested_in: "Fitness, Personal Training",
    user_id: "1234",
    referred_by: "Friend",
    "Source Group": "Website",
    "Source Name": "Free Class",
    venue_name: "",
  });

  // Handle change value input
  const handleChangeValue = (e) => {
    const value = e.target.value;
    setUserInfo((prevState) => ({ ...prevState, [e.target.name]: value }));
  };

  // Handle Submit
  const handleSubmit = async () => {
    const emptyFields = Object.entries(userInfo).filter(
      ([key, value]) => value === ""
    );
    if (emptyFields.length > 0) {
      // If any fields are empty, alert the user with the name of the empty field
      const fieldNames = emptyFields.map(([key, value]) => key).join(", ");
      alert(`The following fields are required: ${fieldNames}`);
    } else {
      await GetFreePassAPI(userInfo);
      await FreePassToStrapiAPI(userInfo);
      setStatus(true);
    }
  };

  // Get State of Studio
  useEffect(() => {
    let state = locations?.data?.filter(
      (item) => item?.attributes?.title == userInfo.city
    )?.[0]?.attributes?.state?.data?.attributes?.Name;
    setUserInfo({ ...userInfo, state: state, venue_name: userInfo.city });
  }, [userInfo.city]);

  // Cleanup Data after submit
  useEffect(() => {
    if (!status) {
      setUserInfo({
        venue_id: "1234",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        city: "",
        state: "",
        interested_in: "Fitness, Personal Training",
        user_id: "1234",
        referred_by: "Friend",
        venue_name: "",
        "Source Group": "Website",
        "Source Name": "Free Class",
      });
    }
  }, [status]);

  return (
    <div
      style={{ backgroundColor: `${props.background}` }}
      className={`${props.background ? `bg-[${props.background}]` : "bg-jade"}`}
    >
      <div
        style={{ backgroundColor: `${props.background}` }}
        className={`w-full max-w-[1440px] mx-auto flex xl:flex-row flex-col gap-[56px] lg:gap-[83px] px-6 py-6 lg:p-[89.5px] ${
          props.background ? `bg-[${props.background}]` : "bg-jade"
        }`}
      >
        <div className={`relative lg:w-[412px] md:w-[70%] w-[90%]`}>
          <FreePassShape color={background} />
          <img
            alt="background"
            className="absolute object-cover w-full h-[calc(100%-3px)] top-[1px]"
            src={img}
          />
        </div>
        <div className="flex flex-col  justify-center">
          <p style={{ color: color ? color : "black" }} className={`heading `}>
            Intro offer - 7 days for $29
          </p>
          <p style={{ color: color ? color : "black" }} className="body  my-6">
            {desc}
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-3 gap-y-6 ">
            <InputBase
              value={userInfo.first_name}
              onChange={handleChangeValue}
              nameInput="first_name"
              isWhite={isWhite}
              holder="First Name*"
            />
            <InputBase
              value={userInfo.last_name}
              onChange={handleChangeValue}
              nameInput="last_name"
              isWhite={isWhite}
              holder="Last name*"
            />
            <InputBase
              value={userInfo.email}
              onChange={handleChangeValue}
              nameInput="email"
              isWhite={isWhite}
              holder="Email address*"
            />
            <InputBase
              value={userInfo.phone}
              onChange={handleChangeValue}
              nameInput="phone"
              isWhite={isWhite}
              holder="Phone Number*"
            />
            <DropdownBase
              holder="Location*"
              color={isWhite ? "#FFFDEF" : "black"}
              options={locations?.data}
              location={userInfo.city}
              setLocation={(selectedLocation) =>
                setUserInfo({ ...userInfo, city: selectedLocation })
              }
            />
            <div className="lg:col-span-2 md:mt-[30px] mt-[0px] flex lg:justify-end items-end">
              <div onClick={handleSubmit}>
                <ButtonNavigate labelText="ENQUIRE NOW" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Succes Get Freepass */}
      {status && <Succes open={status} setOpen={setStatus} />}
    </div>
  );
}

export default FreePass;
