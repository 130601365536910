import React from 'react';

import Studios from './Studios';

function ModalFindStudios(props) {
  const { setModal,onOpenFreePass } = props;
 
  return (
    <div className="fixed lg:top-1/2 top-[120px] left-1/2 bg-white shadow-md h-[80vh] transform border border-[#ccc] rounded-lg -translate-x-1/2  xxx:w-[950px] w-[90%]   z-50 flex items-center">
      <Studios onOpenFreePass={onOpenFreePass}/>
      {/* Close */}
      <div
        onClick={() => setModal(false)}
        className="absolute cursor-pointer right-5 top-5"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19 5L5 19"
            stroke="#111111"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 5L19 19"
            stroke="#111111"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}

export default ModalFindStudios;
