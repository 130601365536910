import React, { useState, useEffect } from "react";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import RebalanceInputBase from "../../common/inputs/RebalanceInputBase";
import DropdownDynamic from "../../common/dropdowns/Dropdown";
import { FranchiseAPI } from "../../utils/FetchData";
import Succes from "../Modal/Succes";
function OwnARebalance(props) {
  const { data } = props;
  const [status, setStatus] = useState<boolean>(false)
  const [userInfo, setUserInfo] = useState({
    BSVName: "PlusFitness",
    Brand: "Rebalance",
    PIN: "Rebalance",
    Source: "https://rebalance.studio",
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
    State: "",
    Funding: "",
    Timeframe: "",
    LocationInterest: "MARGATE"
  })

  // Handle change value input
  const handleChangeValue = (e) => {
    const value = e.target.value
    setUserInfo((prevState) => ({ ...prevState, [e.target.name]: value }))
  }


  // Handle Submit
  const handleSubmit = async () => {
    const emptyFields = Object.entries(userInfo).filter(([key, value]) => value === '');

    if (emptyFields.length > 0) {
      // If any fields are empty, alert the user with the name of the empty field
      const fieldNames = emptyFields.map(([key, value]) => key).join(', ');
      alert(`The following fields are required: ${fieldNames}`);
    } else {
      await FranchiseAPI(userInfo);
      setStatus(true)
    }
  }

  // Cleanup Data after submit
  useEffect(() => {
    if (!status) {
      setUserInfo({
        BSVName: "PlusFitness", Brand: "Rebalance", PIN: "Rebalance",
        Source: "https://rebalance.studio",
        FirstName: "", LastName: "", Email: "", Phone: "", State: "",
        Funding: "", Timeframe: "", LocationInterest: "MARGATE"
      })
    }
  }, [status])
  return (
    <>
      <div className="bg-[#cee3c4]">
        <div className=" border-l-0 border-r-0 overflow-hidden px-2 py-[30px] relative ">
          <div className="flex top-1/2 transform -translate-y-1/2 absolute bg-[#cee3c4] text-[40px] text-[#4F6E6A]">
            <div className="flex lg:w-[1440px] w-full bg-[#cee3c4] lg:gap-[100px] gap-11 items-center logo ">
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">
                  barre
                </p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">
                  pilates
                </p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">yoga</p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">
                  reformer
                </p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">
                  barre
                </p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">
                  pilates
                </p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">yoga</p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">
                  reformer
                </p>
              </span>
            </div>
            <div className="flex w-[1440px] ml-[120px] gap-[100px] items-center logo">
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">
                  barre
                </p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">
                  pilates
                </p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">yoga</p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">
                  reformer
                </p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">
                  barre
                </p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">
                  pilates
                </p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">yoga</p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">
                  reformer
                </p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">
                  barre
                </p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">
                  pilates
                </p>
              </span>
              <span>
                <p className="subheading !font-[800] whitespace-nowrap">yoga</p>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className=" py-[116px] bg-turf xxx:px-[105px] px-5 gap-[42px] justify-between">
        <div className="xxx:w-[1181px] w-full mx-auto flex xxx:flex-row flex-col items-start gap-10 justify-between">
          <div className="py-3 text-white lg:w-[413px] w-full flex flex-col justify-between">
            <p className="heading">
              {data?.data?.attributes?.register_form_group?.title}
            </p>
            <p className="body mt-[50px]">
              {data?.data?.attributes?.register_form_group?.description}
            </p>
          </div>
          <div className="grid xxx:grid-cols-2 grid-cols-1 xxx:w-[724px] w-full col-span-5 gap-x-3 gap-y-6">
            <RebalanceInputBase value={userInfo.FirstName} onChange={handleChangeValue} isWhite nameInput="FirstName" holder="First name*" />
            <RebalanceInputBase value={userInfo.LastName} onChange={handleChangeValue} isWhite nameInput="LastName" holder="Last name*" />
            <RebalanceInputBase value={userInfo.Email} onChange={handleChangeValue} isWhite nameInput="Email" holder="Email address*" />
            <RebalanceInputBase value={userInfo.Phone} onChange={handleChangeValue} isWhite nameInput="Phone" holder="Phone number*" />
            <DropdownDynamic
              color="white"
              selected={userInfo.State}
              setSelected={selectedState => setUserInfo({ ...userInfo, State: selectedState })}
              holder="State*"
              options={["ACT", "NSW", "QLD", "VIC", "WA", "SA", "NT", "TAS"]}
            />
            <DropdownDynamic
              color="white"
              selected={userInfo.Funding}
              setSelected={selectedFund => setUserInfo({ ...userInfo, Funding: selectedFund })}
              holder="Investment capacity*"
              options={["Up to $150,000", "$150,000 - $300,000", "$300,000 +"]}
            />
            <DropdownDynamic
              color="white"
              selected={userInfo.Timeframe}
              setSelected={selectedTime => setUserInfo({ ...userInfo, Timeframe: selectedTime })}
              holder="Timeframe to open*"
              options={["6 + months", "6 - 18 months", "18 + months"]}
            />
            <div className="flex justify-end w-full  xxx:col-span-2 col-span-1 float-right ">
              <div onClick={handleSubmit}>
                <ButtonNavigate width="w-[117px]" labelText="send" />
              </div>
            </div>
          </div>
        </div>
        {/* Modal Succes Get Freepass */}
        {status && <Succes open={status} secondary={true} setOpen={setStatus} />}
      </div>
    </>
  );
}

export default OwnARebalance;
