import axios from 'axios';

// Keepme
export const GetFreePassAPI = async (data: object) => {
    const res = axios({
        method: "post",
        url: `${process.env.KEEP_ME_API}`,
        data: data,
        headers: { "Content-Type": "application/json" },
    })
        .then(() => { })
        .catch((err) => console.log(err))
    return res
}

// Keepme
export const FreePassToStrapiAPI = async (data: any) => {
    const res = axios({
        method: "post",
        url: `${process.env.KEEP_ME_STRAPI_API}`,
        data: {
            "data": {
                firstName: data?.first_name,
                lastName: data?.last_name,
                phoneNumber: data?.phone,
                city: data?.city,
                state: data?.state,
                interestedIn: data?.interested_in,
                userId: data?.user_id,
                referredBy: data?.referred_by,
                venueId: data?.venue_id,
                email: data?.email,
                sourceGroup: data["Source Group"],
                sourceName: data["Source Name"],
                venueName: data?.venue_name,
                message: data?.message
            }
        },
        headers: { "Content-Type": "application/json" },
    })
        .then(() => { })
        .catch((err) => console.log(err))
    return res
}

// Franchise
export const FranchiseAPI = async (data: object) => {
    const res = axios({
        method: "post",
        url: `https://api.franchizemanager.com/fzm/loadenquiry`,
        data: data,
        headers: { "Content-Type": "application/json" },
    })
        .then(() => { })
        .catch((err) => console.log(err))
    return res
}
