import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Link,
  navigate,
} from 'gatsby';

import { useLocation } from '@reach/router';

import { sideBar } from '../../../mock/nav';
import Active from '../../common/buttons/Active';
import { LocationContext } from '../../context/LocationContext';
import { RegisterContext } from '../../context/RegisterContext';
import CreateProfile from '../Modal/CreateProfile';
import FreePass from '../Modal/FreePass';
import ModalFindStudios from '../ModalFindStudio/ModalFindStudios';
import ModalFreePass from '../ModalFindStudio/ModalFreePass';

function Sidebar() {
  const path = useLocation();
  const setGotoPlan = useContext(RegisterContext).setGotoPlan;
  const [open, setOpen] = useState<boolean>(false);
  const [isChangeLocation, setisChangeLocation] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [states, setStates] = useState({ list: ["ACT", "QLD", "NSW", "VIC"], selected: "" });

  const [tab, setTab] = useState("sign in");
  const [modalSignin, setModalSignin] = useState<boolean>(false);
  const addPlan = useContext(RegisterContext).addPlan;
  const setAddPlan = useContext(RegisterContext).setAddPlan;
  const modalChangeLocation = useContext(LocationContext).setModal;

  // Check User has selected location? => and Navigate to timetable
  const handleGetTimeTable = () => {
    localStorage.getItem("studio") !== null ? navigate("/timetable") : setisChangeLocation(true);
  };

  // Approved the time table route if the user has selected location
  useEffect(() => {
    if (localStorage.getItem("studio") == null && path.pathname == "/timetable") {
      navigate("/");
    }
  }, [path.pathname]);

  const handleGotoTimeTable = () => {
    window.localStorage.getItem("studio") && navigate("/timetable");
  };

  const handleScrollWithSamePage = () => {
    setOpen(false);
    setGotoPlan(true);
  };

  const handleAddPlan = () => {
    setModal(false);
    setAddPlan(true);
  };

  const handlleRegister = () => {
    // if (localStorage.getItem('studio') == null) {
    //     modalChangeLocation(true)
    // } else {
    //     setModalSignin(true)
    // }
    setModalFindStudio(true);
    setOpen(false)
  };

  const [modalFindStudio, setModalFindStudio] = useState<boolean>(false);
  const [modalFreePass, setModalFreePass] = useState(false);

  const handleCloseModalFreePass = useCallback(() => {
    setModalFindStudio(!modalFindStudio);
    setModalFreePass(!modalFreePass);
  }, []);

  return (
    <div>
      {/* MODAL FIND STUDIOS */}
      {modalFindStudio && !modalFreePass && (
        <ModalFindStudios onOpenFreePass={handleCloseModalFreePass} setModal={setModalFindStudio} />
      )}
      {modalFreePass && <ModalFreePass onClose={handleCloseModalFreePass} setModal={setModalFreePass} />}
      <div className='w-full p-[18px] bg-[rgba(206,204,204,0.5)] border-b border-b-turf  flex items-center justify-between '>
        <Link to='/'>
          <svg xmlns='http://www.w3.org/2000/svg' width='158' height='27' viewBox='0 0 158 27' fill='none'>
            <g clipPath='url(#clip0_107_145)'>
              <path
                d='M9.70568 6.98926C7.34208 6.98926 5.09867 8.13231 3.62005 10.0689V7.20767H0V26.392H3.62005V15.7623C3.62005 13.0139 5.62674 10.2291 9.46531 10.2291C10.1063 10.2291 10.4086 10.2655 10.8602 10.4038L11.2171 10.4111V7.26228L11.0969 7.2186C10.689 7.06934 10.343 6.98926 9.70932 6.98926H9.70568Z'
                fill='#4F6E6A'
              />
              <path
                d='M42.5522 6.77093C39.8462 6.77093 37.6939 7.72469 35.9822 9.67952V0H32.3621V26.3921H35.9822V24.1424C37.6429 26.0717 39.6496 26.9381 42.432 26.9381C48.0733 26.9381 51.5768 23.0758 51.5768 16.8545C51.5768 10.6333 48.1206 6.77093 42.5522 6.77093ZM47.8766 16.8909C47.8766 21.4049 45.7498 24.0987 42.1916 24.0987C38.1782 24.0987 35.7819 21.4049 35.7819 16.8909C35.7819 13.389 37.4754 9.64676 42.2317 9.64676C45.768 9.64676 47.8766 12.3551 47.8766 16.8909Z'
                fill='#4F6E6A'
              />
              <path
                d='M70.2526 23.6218C69.9284 23.3561 69.7864 22.8428 69.7864 21.9509V12.9958C69.7864 8.91507 67.1424 6.84375 61.9272 6.84375C56.7119 6.84375 53.6018 9.29002 53.467 13.389L53.4597 13.5783H56.956L56.9632 13.4035C57.0798 10.6915 58.6676 9.42835 61.9672 9.42835C64.8552 9.42835 66.4468 10.5386 66.4468 12.559C66.4468 14.4155 64.5675 14.6704 61.7269 15.0599L61.6212 15.0744C56.4097 15.8061 52.4218 16.7308 52.4218 21.3357C52.4218 24.6811 55.186 26.7598 59.6364 26.7598C62.4662 26.7598 64.7642 25.8169 66.4723 23.9531C66.8802 25.7441 68.0638 26.5777 70.1651 26.5777C71.0429 26.5777 71.5819 26.4248 71.997 26.2756L72.1172 26.2319V23.7747H71.7785C71.0574 23.924 70.5585 23.873 70.2526 23.6255V23.6218ZM66.4868 16.3558V19.2571C66.4868 21.1428 64.8225 24.1351 60.3575 24.1351C57.586 24.1351 56.2385 23.1959 56.2385 21.2593C56.2385 18.7548 58.3982 18.2124 61.9527 17.6154C63.0999 17.4261 65.1466 17.022 66.4832 16.3558H66.4868Z'
                fill='#4F6E6A'
              />
              <path d='M77.4633 0H73.8433V26.3921H77.4633V0Z' fill='#4F6E6A' />
              <path
                d='M97.057 23.6218C96.7328 23.3561 96.5908 22.8428 96.5908 21.9509V12.9958C96.5908 8.91507 93.9468 6.84375 88.7316 6.84375C83.5164 6.84375 80.4062 9.29002 80.2714 13.389L80.2641 13.5783H83.7604L83.7677 13.4035C83.8842 10.6915 85.4721 9.42835 88.7716 9.42835C91.6597 9.42835 93.2512 10.5386 93.2512 12.559C93.2512 14.4155 91.372 14.6704 88.5313 15.0599L88.4257 15.0744C83.2141 15.8061 79.2262 16.7308 79.2262 21.3357C79.2262 24.6811 81.9904 26.7598 86.4408 26.7598C89.2706 26.7598 91.5686 25.8169 93.2803 23.9531C93.6882 25.7441 94.8718 26.5777 96.9732 26.5777C97.8509 26.5777 98.3899 26.4248 98.8051 26.2756L98.9253 26.2319V23.7747H98.5866C97.8618 23.924 97.3629 23.873 97.0606 23.6255L97.057 23.6218ZM93.2912 16.3558V19.2571C93.2912 21.1428 91.6269 24.1351 87.1619 24.1351C84.3904 24.1351 83.0429 23.1959 83.0429 21.2593C83.0429 18.7548 85.2026 18.2124 88.7571 17.6154C89.9043 17.4261 91.951 17.022 93.2876 16.3558H93.2912Z'
                fill='#4F6E6A'
              />
              <path
                d='M110.277 6.771C107.553 6.771 105.426 7.65559 103.787 9.47209V7.20783H100.167V26.3921H103.787V14.634C103.787 11.6745 106.06 9.61042 109.312 9.61042C112.186 9.61042 113.832 10.9355 113.832 13.2507V26.3921H117.452V12.9959C117.452 9.09714 114.768 6.771 110.277 6.771Z'
                fill='#4F6E6A'
              />
              <path
                d='M157.512 17.5096C157.512 10.8625 153.779 6.73438 147.767 6.73438C141.754 6.73438 137.737 10.9753 137.737 16.818C137.737 22.6606 141.943 26.9016 147.967 26.9016C152.767 26.9016 156.085 24.5427 157.064 20.4255L157.119 20.1998H153.575L153.539 20.3308C152.847 22.7698 150.92 24.0585 147.967 24.0585C144.256 24.0585 141.772 21.6231 141.451 17.688H157.512V17.506V17.5096ZM141.546 15.107C142.129 11.4959 144.383 9.42819 147.767 9.42819C151.15 9.42819 153.379 11.4959 153.641 15.107H141.546Z'
                fill='#4F6E6A'
              />
              <path
                d='M135.29 22.0711C134.747 23.1413 133.99 23.8293 132.97 24.1788C131.59 24.652 130.122 24.5246 128.483 23.7966C125.923 22.6571 123.938 20.6914 122.583 17.9503C122.401 17.5826 122.259 17.1967 122.135 16.8327C121.869 16.061 121.724 15.2637 121.695 14.4556L121.68 13.9933C121.571 11.365 123.235 9.35918 125.639 9.22813C126.884 9.15897 128.108 9.44291 129.383 10.0945C130.661 10.7461 131.761 11.598 132.744 12.701L132.828 12.7956L136.594 11.2958L136.477 11.1138C135.985 10.3457 135.385 9.64677 134.693 9.03156C131.368 6.06836 126.309 5.99556 122.926 8.85682C118.945 12.2241 118.086 18.2706 120.966 22.6207C122.58 25.0597 124.958 26.5741 127.656 26.8872C127.995 26.9272 128.33 26.9454 128.669 26.9454C130.992 26.9454 133.236 26.0208 135.068 24.288C135.519 23.8621 135.898 23.3597 136.266 22.8719L135.286 22.0783L135.29 22.0711Z'
                fill='#4F6E6A'
              />
              <path
                d='M30.5047 15.3001C29.9985 12.6682 28.8914 10.6187 27.1214 9.04243C23.8 6.08288 18.745 6.00643 15.3653 8.8677C11.3884 12.2313 10.5289 18.2706 13.406 22.6207C15.0194 25.0597 17.3939 26.5704 20.0925 26.8798C20.4312 26.9199 20.7663 26.9381 21.105 26.9381C23.4285 26.9381 25.6683 26.0134 27.5002 24.2843C27.9518 23.8584 28.3305 23.356 28.6984 22.8682L27.6859 22.0965C27.3581 22.7408 26.9539 23.2468 26.4586 23.6218C26.0507 23.8766 25.6719 24.0841 25.3951 24.1787C24.0149 24.6483 22.5508 24.5246 20.9156 23.7965C18.3553 22.6607 16.3741 20.695 15.0266 17.9575C14.7972 17.4952 14.6297 16.9928 14.4512 16.4577C14.3893 16.272 14.3274 16.0864 14.2582 15.8934H30.5193V15.7151C30.5193 15.6568 30.5193 15.6059 30.5229 15.5622C30.5229 15.4675 30.5302 15.3874 30.512 15.3037L30.5047 15.3001ZM14.098 13.833C13.9923 12.7046 14.3274 11.6198 15.0485 10.7534C15.8024 9.84693 16.8767 9.31181 18.0676 9.24629C19.3095 9.17712 20.5332 9.46106 21.8079 10.1127C23.4941 10.9754 24.8926 12.1949 26.0653 13.833H14.098Z'
                fill='#4F6E6A'
              />
            </g>
            <defs>
              <clipPath id='clip0_107_145'>
                <rect width='158' height='27' fill='white' />
              </clipPath>
            </defs>
          </svg>
        </Link>
        <div onClick={() => setOpen(!open)} className='w-[24px] cursor-pointer mr-4 '>
          <svg xmlns='http://www.w3.org/2000/svg' width='37' height='14' viewBox='0 0 37 14' fill='none'>
            <path d='M0 1H37' stroke='#4F6E6A' stroke-width='2'></path>
            <path d='M0 13H37' stroke='#4F6E6A' stroke-width='2'></path>
          </svg>
        </div>
      </div>
      <div
        style={{ zIndex: 999999999 }}
        className={` relative  top-0 left-0 bg-[#BBCCF3] w-full transition-all ${
          open ? "h-[100vh] block transition-transform " : "h-[0px] hidden transition-opacity"
        } `}
      >
        <div className='flex items-center justify-between -mt-[65px] p-[18px]'>
          <Link to='/'>
            <svg xmlns='http://www.w3.org/2000/svg' width='158' height='27' viewBox='0 0 158 27' fill='none'>
              <g clipPath='url(#clip0_107_145)'>
                <path
                  d='M9.70568 6.98926C7.34208 6.98926 5.09867 8.13231 3.62005 10.0689V7.20767H0V26.392H3.62005V15.7623C3.62005 13.0139 5.62674 10.2291 9.46531 10.2291C10.1063 10.2291 10.4086 10.2655 10.8602 10.4038L11.2171 10.4111V7.26228L11.0969 7.2186C10.689 7.06934 10.343 6.98926 9.70932 6.98926H9.70568Z'
                  fill='#4F6E6A'
                />
                <path
                  d='M42.5522 6.77093C39.8462 6.77093 37.6939 7.72469 35.9822 9.67952V0H32.3621V26.3921H35.9822V24.1424C37.6429 26.0717 39.6496 26.9381 42.432 26.9381C48.0733 26.9381 51.5768 23.0758 51.5768 16.8545C51.5768 10.6333 48.1206 6.77093 42.5522 6.77093ZM47.8766 16.8909C47.8766 21.4049 45.7498 24.0987 42.1916 24.0987C38.1782 24.0987 35.7819 21.4049 35.7819 16.8909C35.7819 13.389 37.4754 9.64676 42.2317 9.64676C45.768 9.64676 47.8766 12.3551 47.8766 16.8909Z'
                  fill='#4F6E6A'
                />
                <path
                  d='M70.2526 23.6218C69.9284 23.3561 69.7864 22.8428 69.7864 21.9509V12.9958C69.7864 8.91507 67.1424 6.84375 61.9272 6.84375C56.7119 6.84375 53.6018 9.29002 53.467 13.389L53.4597 13.5783H56.956L56.9632 13.4035C57.0798 10.6915 58.6676 9.42835 61.9672 9.42835C64.8552 9.42835 66.4468 10.5386 66.4468 12.559C66.4468 14.4155 64.5675 14.6704 61.7269 15.0599L61.6212 15.0744C56.4097 15.8061 52.4218 16.7308 52.4218 21.3357C52.4218 24.6811 55.186 26.7598 59.6364 26.7598C62.4662 26.7598 64.7642 25.8169 66.4723 23.9531C66.8802 25.7441 68.0638 26.5777 70.1651 26.5777C71.0429 26.5777 71.5819 26.4248 71.997 26.2756L72.1172 26.2319V23.7747H71.7785C71.0574 23.924 70.5585 23.873 70.2526 23.6255V23.6218ZM66.4868 16.3558V19.2571C66.4868 21.1428 64.8225 24.1351 60.3575 24.1351C57.586 24.1351 56.2385 23.1959 56.2385 21.2593C56.2385 18.7548 58.3982 18.2124 61.9527 17.6154C63.0999 17.4261 65.1466 17.022 66.4832 16.3558H66.4868Z'
                  fill='#4F6E6A'
                />
                <path d='M77.4633 0H73.8433V26.3921H77.4633V0Z' fill='#4F6E6A' />
                <path
                  d='M97.057 23.6218C96.7328 23.3561 96.5908 22.8428 96.5908 21.9509V12.9958C96.5908 8.91507 93.9468 6.84375 88.7316 6.84375C83.5164 6.84375 80.4062 9.29002 80.2714 13.389L80.2641 13.5783H83.7604L83.7677 13.4035C83.8842 10.6915 85.4721 9.42835 88.7716 9.42835C91.6597 9.42835 93.2512 10.5386 93.2512 12.559C93.2512 14.4155 91.372 14.6704 88.5313 15.0599L88.4257 15.0744C83.2141 15.8061 79.2262 16.7308 79.2262 21.3357C79.2262 24.6811 81.9904 26.7598 86.4408 26.7598C89.2706 26.7598 91.5686 25.8169 93.2803 23.9531C93.6882 25.7441 94.8718 26.5777 96.9732 26.5777C97.8509 26.5777 98.3899 26.4248 98.8051 26.2756L98.9253 26.2319V23.7747H98.5866C97.8618 23.924 97.3629 23.873 97.0606 23.6255L97.057 23.6218ZM93.2912 16.3558V19.2571C93.2912 21.1428 91.6269 24.1351 87.1619 24.1351C84.3904 24.1351 83.0429 23.1959 83.0429 21.2593C83.0429 18.7548 85.2026 18.2124 88.7571 17.6154C89.9043 17.4261 91.951 17.022 93.2876 16.3558H93.2912Z'
                  fill='#4F6E6A'
                />
                <path
                  d='M110.277 6.771C107.553 6.771 105.426 7.65559 103.787 9.47209V7.20783H100.167V26.3921H103.787V14.634C103.787 11.6745 106.06 9.61042 109.312 9.61042C112.186 9.61042 113.832 10.9355 113.832 13.2507V26.3921H117.452V12.9959C117.452 9.09714 114.768 6.771 110.277 6.771Z'
                  fill='#4F6E6A'
                />
                <path
                  d='M157.512 17.5096C157.512 10.8625 153.779 6.73438 147.767 6.73438C141.754 6.73438 137.737 10.9753 137.737 16.818C137.737 22.6606 141.943 26.9016 147.967 26.9016C152.767 26.9016 156.085 24.5427 157.064 20.4255L157.119 20.1998H153.575L153.539 20.3308C152.847 22.7698 150.92 24.0585 147.967 24.0585C144.256 24.0585 141.772 21.6231 141.451 17.688H157.512V17.506V17.5096ZM141.546 15.107C142.129 11.4959 144.383 9.42819 147.767 9.42819C151.15 9.42819 153.379 11.4959 153.641 15.107H141.546Z'
                  fill='#4F6E6A'
                />
                <path
                  d='M135.29 22.0711C134.747 23.1413 133.99 23.8293 132.97 24.1788C131.59 24.652 130.122 24.5246 128.483 23.7966C125.923 22.6571 123.938 20.6914 122.583 17.9503C122.401 17.5826 122.259 17.1967 122.135 16.8327C121.869 16.061 121.724 15.2637 121.695 14.4556L121.68 13.9933C121.571 11.365 123.235 9.35918 125.639 9.22813C126.884 9.15897 128.108 9.44291 129.383 10.0945C130.661 10.7461 131.761 11.598 132.744 12.701L132.828 12.7956L136.594 11.2958L136.477 11.1138C135.985 10.3457 135.385 9.64677 134.693 9.03156C131.368 6.06836 126.309 5.99556 122.926 8.85682C118.945 12.2241 118.086 18.2706 120.966 22.6207C122.58 25.0597 124.958 26.5741 127.656 26.8872C127.995 26.9272 128.33 26.9454 128.669 26.9454C130.992 26.9454 133.236 26.0208 135.068 24.288C135.519 23.8621 135.898 23.3597 136.266 22.8719L135.286 22.0783L135.29 22.0711Z'
                  fill='#4F6E6A'
                />
                <path
                  d='M30.5047 15.3001C29.9985 12.6682 28.8914 10.6187 27.1214 9.04243C23.8 6.08288 18.745 6.00643 15.3653 8.8677C11.3884 12.2313 10.5289 18.2706 13.406 22.6207C15.0194 25.0597 17.3939 26.5704 20.0925 26.8798C20.4312 26.9199 20.7663 26.9381 21.105 26.9381C23.4285 26.9381 25.6683 26.0134 27.5002 24.2843C27.9518 23.8584 28.3305 23.356 28.6984 22.8682L27.6859 22.0965C27.3581 22.7408 26.9539 23.2468 26.4586 23.6218C26.0507 23.8766 25.6719 24.0841 25.3951 24.1787C24.0149 24.6483 22.5508 24.5246 20.9156 23.7965C18.3553 22.6607 16.3741 20.695 15.0266 17.9575C14.7972 17.4952 14.6297 16.9928 14.4512 16.4577C14.3893 16.272 14.3274 16.0864 14.2582 15.8934H30.5193V15.7151C30.5193 15.6568 30.5193 15.6059 30.5229 15.5622C30.5229 15.4675 30.5302 15.3874 30.512 15.3037L30.5047 15.3001ZM14.098 13.833C13.9923 12.7046 14.3274 11.6198 15.0485 10.7534C15.8024 9.84693 16.8767 9.31181 18.0676 9.24629C19.3095 9.17712 20.5332 9.46106 21.8079 10.1127C23.4941 10.9754 24.8926 12.1949 26.0653 13.833H14.098Z'
                  fill='#4F6E6A'
                />
              </g>
              <defs>
                <clipPath id='clip0_107_145'>
                  <rect width='158' height='27' fill='white' />
                </clipPath>
              </defs>
            </svg>
          </Link>
          <div onClick={() => setOpen(!open)} className='w-[24px] cursor-pointer absolute  z-50 right-4'>
            <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'>
              <path d='M1 22L22 0.999999' stroke='#4F6E6A' stroke-width='2'></path>
              <path d='M22 22L0.999998 1' stroke='#4F6E6A' stroke-width='2'></path>
            </svg>
          </div>
          <ul className='flex gap-5 items-center cursor-pointer justify-center flex-col h-full mx-auto absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full'>
            {sideBar.map((item: object, idx: number) => (
              <li
                key={idx}
                onClick={() => (item.name === "timetable" ? handleGetTimeTable() : navigate(`/${item.path}`))}
                className='subheading text-white hover:text '
              >
                {item.name}
              </li>
            ))}
            {isChangeLocation && (
              <ul className='px-6 flex flex-col absolute py-6 rounded-md left-1/2 transform -translate-x-1/2 top-1/2 z-10 bg-turf -translate-y-1/2 gap-[7px]  mt-3'>
                <h2 className='text-white heading3 mb-3'>Select Location</h2>
                {states.list.map((item: string, index: number) => (
                  <li
                    onClick={() => {
                      window.localStorage.setItem("studio", JSON.stringify(item));
                      handleGotoTimeTable();
                    }}
                    onMouseEnter={() => setStates({ ...states, selected: item })}
                    key={index}
                    className='flex justify-between cursor-pointer items-center'
                  >
                    <p style={{ fontSize: 16 }} className='heading3 text-white'>
                      {item}
                    </p>
                    <div className='w-[18px] flex items-center justify-center h-[18px] border border-white rounded-[50%]'>
                      {item == states.selected && <span className='w-[15px] h-[15px] rounded-[50%] bg-white'></span>}
                    </div>
                  </li>
                ))}
              </ul>
            )}
            <div className='mt-[70px] relative'>
              <button
                onClick={handlleRegister}
                // onClick={() => path.pathname == '/memberships' ? handleScrollWithSamePage() : navigate('/memberships', { state: { isScroll: true } })}
                className='rounded-[6px] border border-white hover:border-black hover:text-black transition-all h-[40px] px-[20px] uppercase text-white text-sm subheading3'
              >
                join now
              </button>
            </div>
            <div className='mt-[0px]'>
              <button
                onClick={() => {
                  setModal(true);
                  setOpen(false);
                }}
                className='rounded-[6px] hover:bg-transparent hover:border-black hover:border bg-flexi   hover:text-[#000000] transition-all h-[40px] px-[20px] uppercase text-black text-sm subheading3'
              >
                Intro Offer
              </button>
            </div>
          </ul>
        </div>
      </div>
      {modal && <FreePass setModal={setModal} />}

      {modalSignin && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .4)", zIndex: 999999999 }}
          className='fixed w-full  h-full z-40 left-0 top-0  border border-gray  rounded-md'
        >
          <div
            style={{ zIndex: 99999999999 }}
            className='relative z-50 lg:w-[850px]  w-full h-[90vh]  bg-white left-1/2 transformt rounded-md -translate-x-1/2  top-0'
          >
            <div className='pt-16 px-10 md:flex hidden mx-auto  items-center justify-between'>
              <CreateProfile />
              <div className='-mt-12 '>
                <h2 style={{ fontSize: 30 }} className='heading3 text-center'>
                  Create an account
                </h2>
                <p className='heading3 text-center mb-[30px]'>Please create an account to continue</p>
                <div onClick={handleAddPlan} className='mx-auto w-full flex justify-center'>
                  <Active text='sign up' />
                </div>
              </div>
            </div>
            {/* Mobile */}
            <div className='md:hidden block'>
              <div className='flex flex-col  pt-[50px]'>
                <div className='flex items-start'>
                  <div onClick={() => setTab("sign in")} className='ml-2'>
                    <Active text='sign in' />
                  </div>
                  <div onClick={() => setTab("sign up")} className='ml-5'>
                    <Active text='sign up' />
                  </div>
                </div>
                <div className='mt-[20px]'>
                  {tab == "sign in" ? (
                    <CreateProfile />
                  ) : (
                    <div className='min-h-full mt-5'>
                      <h2 style={{ fontSize: 30 }} className='heading3 text-center'>
                        Create an account
                      </h2>
                      <p className='heading3 text-center mb-[30px]'>Please create an account to continue</p>
                      <div onClick={handleAddPlan} className='mx-auto w-full flex justify-center'>
                        <Active text='sign up' />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              onClick={() => setModalSignin(false)}
              style={{ zIndex: 999999999999999 }}
              className='absolute cursor-pointer right-4 top-4'
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                <path d='M19 5L5 19' stroke='#545459' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M5 5L19 19' stroke='#545459' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
              </svg>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sidebar;
