import React, { useEffect, useState } from 'react'
interface IProps {
    booking: boolean,
    tab: string,
    setBooking: () => void
    state: string
}
function BookingClass(props: IProps) {
    const { booking, setBooking, tab, state } = props
    const [id, setId] = useState<string>('')

    let states = {
        VIC: [
            { class: 'yoga', id: 194347 },
            { class: 'pilates', id: 194349 },
            { class: 'reformer', id: 194350 },
            { class: 'barre', id: 194351 },
        ],
        NSW: [
            { class: 'yoga', id: 194354 },
            { class: 'pilates', id: 194356 },
            { class: 'reformer', id: 194357 },
            { class: 'barre', id: 194358 }
        ],
        QLD: [
            { class: 'yoga', id: 194135 },
            { class: 'pilates', id: 194195 },
            { class: 'reformer', id: 194196 },
            { class: 'barre', id: 194194 }
        ],
        ACT: [
            { class: 'yoga', id: 194377 },
            { class: 'pilates', id: 194378 },
            { class: 'reformer', id: 194376 },
            { class: 'barre', id: 194379 }
        ],
    }


    function getObjectByTabAndState(tab, state) {
        if (states[state]) {
            return states[state].find(obj => obj.class === tab);
        } else { return null; }
    }

    useEffect(() => {
        if (tab !== '' && state !== '') {
            const obj = getObjectByTabAndState(tab, state);
            setId(obj?.id)
        }
    }, [tab, booking])

    return (
        <div onClick={() => setBooking(false)}
            style={{ zIndex: 9999999999, backgroundColor: 'rgba(0, 0, 0, .4)' }}
            className='fixed top-0 left-0  w-full h-full '>
            <div style={{ zIndex: 999999999999999 }}
                className='relative  w-full rounded-lg overflow-hidden left-1/2 h-[100vh] top-1/2  transform -translate-x-1/2 -translate-y-1/2    z-40'>
                <div className='lg:w-[768px] w-full mx-auto mt-3 rounded-tl-md rounded-tr-md border-b relative border-b-gray h-[70px] bg-[#F8F9FA]'>
                    <div
                        style={{ zIndex: 999999999999999 }}
                        className='absolute cursor-pointer right-4 top-4'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M19 5L5 19" stroke="#545459" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5 5L19 19" stroke="#545459" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
                {id && (
                    <iframe
                        src={`https://brandedweb.mindbodyonline.com/iframe/schedule/${id}`}
                        className='h-[86%] rounded-bl-lg rounded-br-lg mx-auto lg:w-[768px] w-full' ></iframe>
                )}
            </div>
        </div>
    )
}

export default BookingClass