import React, { useRef, useState, useContext, useCallback } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import axios from 'axios';
import marker from '../assets/images/studio/marker.svg'
import markerActive from '../assets/images/studio/cursorPointer.svg'
import { LocationContext } from '../context/LocationContext';
const containerStyle = {
    width: '100%',
    height: '100%'
};


let locations: [] | undefined
let studios: [] | undefined

const API = process.env.STRAPI_API_URL;
const url = `${API}/api/studios?populate=image,access,address.state,address.country,background,state`;
axios.get(url).then((res) => {
    studios = res?.data
    locations = res?.data?.data?.map((item: object) => {
        return {
            lat: item?.attributes?.location?.coordinates?.[1],
            lng: item?.attributes?.location?.coordinates?.[0],
        }
    })
})


function Map(props) {
    const { clubSelected } = props
    const [currentInfo, setCurrentInfo] = useState<object>({ isShow: false, location: '', info: {} })
    const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: `AIzaSyBVWpu2fya-T7OpNSuFheFxe32Nc_eXvVg` })


    // Map Setting
    const mapRef = useRef<GoogleMap>()
    const onload = useCallback((map: any) => (mapRef.current = map), [])

    // Get Info by Marker
    const getInfoStudio = (location: object) => {
        const findStudio = studios?.data?.find((e: object) => { return e?.attributes?.location?.coordinates?.[0] === location?.lng })
        setCurrentInfo({ ...currentInfo, info: findStudio })
    }

    function FindStudio() {
        const getStudio = studios.data?.find(item => item.attributes?.title?.toUpperCase() == clubSelected?.title?.toUpperCase())
        const location = {
            lat: getStudio?.attributes?.location?.coordinates?.[1],
            lng: getStudio?.attributes?.location?.coordinates?.[0],
        }
        return mapRef.current?.panTo(location)
    }

    clubSelected !== undefined && FindStudio()


    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={locations?.[0]}
            zoom={10}
            onLoad={onload}
        >
            {locations?.map((location: object, idx: number) => (
                <div key={idx}>
                    <Marker
                        noClustererRedraw
                        noRedraw={true}
                        onClick={() => getInfoStudio(location)}
                        position={location} icon={currentInfo?.info?.attributes?.location?.coordinates?.[0] === location?.lng ? markerActive : marker} />
                    {currentInfo?.info?.attributes && (
                        <div className='text-black pb-4 bg-lilac w-[261px] border border-lilac absolute left-0 top-0'>
                            <img src={currentInfo?.info?.attributes?.background?.data?.attributes?.url} className='w-full h-[149px] object-cover' />
                            <div className='pl-[17px] h-full'>
                                <h5 style={{ fontSize: '21px' }} className='text-black mb-[10px] text-[20px] leading-[100%] mt-2  subheading font-bold capitalize'>{currentInfo?.info?.attributes?.title}</h5>
                                <span style={{ fontSize: '14px' }} className=' font-normal capitalize text-sm leading-[160%] subheading text-black '>{currentInfo?.info?.attributes?.fullAddress}</span>
                                <br />
                                <span style={{ fontSize: '13px' }} className='text-black  pt-[6px] font-normal subheading text-sm'>{currentInfo?.info?.attributes?.phoneNumber}</span>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </GoogleMap>
    ) : <></>
}

export default React.memo(Map)