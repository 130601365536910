import React, { useEffect, useState } from "react";

import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import DropdownBase from "../../common/dropdowns/DropdownBase";
import InputBase from "../../common/inputs/RebalanceInputBase";
import useFetch from "../../hooks/useFetch";
import { FreePassToStrapiAPI, GetFreePassAPI } from "../../utils/FetchData";
import Succes from "./Succes";

function FreePass(props) {
  const { setModal } = props;
  const { data } = useFetch({ params: "global", populate: "freePass" });

  const [status, setStatus] = useState<boolean>(false);
  const { data: locations } = useFetch({
    params: "studios",
    populate: "state",
  });

  const [userInfo, setUserInfo] = useState({
    venue_id: "1234",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    interested_in: "Fitness, Personal Training",
    user_id: "1234",
    referred_by: "Friend",
    "Source Group": "Website",
    "Source Name": "Free Class",
    venue_name: "",
  });

  // Handle change value input
  const handleChangeValue = (e) => {
    const value = e.target.value;
    setUserInfo((prevState) => ({ ...prevState, [e.target.name]: value }));
  };

  // Handle Submit
  const handleSubmit = async () => {
    const emptyFields = Object.entries(userInfo).filter(
      ([key, value]) => value === ""
    );
    if (emptyFields.length > 0) {
      const fieldNames = emptyFields.map(([key, value]) => key).join(", ");
      alert(`The following fields are required: ${fieldNames}`);
    } else {
      await GetFreePassAPI(userInfo);
      await FreePassToStrapiAPI(userInfo);
      setStatus(true);
    }
  };

  useEffect(() => {
    let state = locations?.data?.filter(
      (item) => item?.attributes?.title == userInfo.city
    )?.[0]?.attributes?.state?.data?.attributes?.Name;
    setUserInfo({ ...userInfo, state: state, venue_name: userInfo.city });
  }, [userInfo.city]);

  // Cleanup Data after submit
  useEffect(() => {
    if (!status) {
      setUserInfo({
        venue_id: "1234",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        city: "",
        state: "",
        interested_in: "Fitness, Personal Training",
        user_id: "1234",
        referred_by: "Friend",
        venue_name: "",
        "Source Group": "Website",
        "Source Name": "Free Class",
      });
    }
  }, [status]);

  return (
    <div className="fixed lg:top-1/2 top-[80px] z-50 left-1/2  transform border border-[#ccc] rounded-lg -translate-x-1/2  xxx:w-[900px] w-[90%] lg:h-[600px] h-[510px]   z-50 flex items-center">
      <img
        src={data?.data?.attributes?.freePass?.data?.attributes?.url}
        className="w-1/2 lg:block hidden h-full object-cover"
      />
      <div className="flex flex-col lg:w-1/2 freeClass w-full bg-white h-full justify-center">
        <p
          style={{ fontSize: "30px" }}
          className={`heading text-black text-center`}
        >
          {data?.data?.attributes?.free_pass?.title}
        </p>
        <div className="grid grid-cols-1 mx-auto gap-4 mt-7 w-[75%]">
          <InputBase
            value={userInfo.first_name}
            onChange={handleChangeValue}
            nameInput="first_name"
            holder="First Name*"
          />
          <InputBase
            value={userInfo.last_name}
            onChange={handleChangeValue}
            nameInput="last_name"
            holder="Last name*"
          />
          <InputBase
            value={userInfo.email}
            onChange={handleChangeValue}
            nameInput="email"
            holder="Email address*"
          />
          <InputBase
            value={userInfo.phone}
            onChange={handleChangeValue}
            nameInput="phone"
            holder="Phone Number*"
          />
          <div>
            <DropdownBase
              holder="Location*"
              color={"black"}
              options={locations?.data}
              location={userInfo.city}
              setLocation={(selectedLocation) =>
                setUserInfo({ ...userInfo, city: selectedLocation })
              }
            />
          </div>
          <div className="mt-[30px] flex lg:justify-end items-end">
            <div onClick={handleSubmit}>
              <ButtonNavigate labelText="ENQUIRE NOW" />
            </div>
          </div>
        </div>
      </div>
      {/* Close */}
      <div
        onClick={() => setModal(false)}
        className="absolute cursor-pointer right-5 top-5"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19 5L5 19"
            stroke="#111111"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 5L19 19"
            stroke="#111111"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      {/* Modal Succes Get Freepass */}
      {status && <Succes open={status} setOpen={setStatus} />}
    </div>
  );
}

export default FreePass;
