import React from 'react';

import TextAnimation from '../own-a-studio/TextAnimation';

function Banner(props) {
    const { data } = props

    return (
        <div> <div className="relative">
            {data?.data?.attributes?.banner_1?.background?.data?.attributes?.url?.slice(-3) == 'mp4'
                ? <video src={data?.data?.attributes?.banner_1?.background?.data?.attributes?.url}
                 className=" w-[100vw] lg:h-[688px] h-[518px] object-cover overflow-hidden" ></video>
                : <>
                    <img src={data?.data?.attributes?.banner_1?.background?.data?.attributes?.url} 
                    className=" w-[100vw] object-cover object-bottom md:block hidden  overflow-hidden"
                    // className=" w-[100vw] md:block hidden h-[688px] object-cover overflow-hidden"
                     />
                    <img src={data?.data?.attributes?.banner_1?.mobileImage?.data?.attributes?.url}
                    //  className=" w-[100vw] md:hidden block h-[518px] object-cover overflow-hidden" 
                    className=" w-[100vw] object-cover object-bottom md:hidden block overflow-hidden"
                     />

                </>

            }
            <p className="heading text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full text-center ">
                {data?.data?.attributes?.banner_1?.title}
            </p>
        </div>
            <TextAnimation />
        </div>

    )
}

export default Banner