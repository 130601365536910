import React, { useState } from 'react'

function DropdownDynamic(props) {
  const { holder, options, color, selected, setSelected } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  // const [selected, setSelected] = useState<string>('')
  return (
    <div className='relative'>
      <div onClick={() => setIsOpen(!isOpen)}
        style={{ border: `1px solid ${color}` }}
        className={`py-[9.5px] cursor-pointer pl-[12px] pr-[18px] rounded-[6px] flex items-center justify-between`}>
        <span style={{ color: color }}
          className={`w-full placeholder:text-white h-full text-[${color}] bg-transparent heading3 border-none outline-none`}>
          {selected !== '' ? selected : holder}
        </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
          <path d="M1 1.5L6.29289 6.79289C6.62623 7.12623 6.79289 7.29289 7 7.29289C7.20711 7.29289 7.37377 7.12623 7.70711 6.79289L13 1.5"
            stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
      {/* options */}
      {isOpen && (
        <div className='absolute w-full max-h-[200px] dropdown-scrollbar overflow-y-auto bg-white z-50 overflow-hidden rounded-bl-[6px] rounded-br-[6px] cursor-pointer left-0 top-[45px]'>
          {options?.map(item => (
            <div onClick={() => { setSelected(item); setIsOpen(false) }} className='py-[10px] px-[12px] heading3 bg-white hover:bg-apricot transition-all'>{item}</div>
          ))}
        </div>
      )}
    </div>
  )
}

export default DropdownDynamic