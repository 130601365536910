import React from "react";

function OwnYoga(props) {
  const { data } = props
  return (
    <>
      <div className=" py-[112px] lg:px-[306px] px-5 flex flex-col gap-[50px]">
        <p className="heading text-black text-center lg:w-[819px] w-full mx-auto ">
          {data?.data?.attributes?.text_group_1?.title}
        </p>
        <p className="body text-black text-center lg:w-[799px] w-full mx-auto">
          {data?.data?.attributes?.text_group_1?.description}
        </p>
      </div>
    </>
  );
}

export default OwnYoga;
