import React, {
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useLocation } from '@reach/router';

import Active from '../../common/buttons/Active';
import { LocationContext } from '../../context/LocationContext';
import { RegisterContext } from '../../context/RegisterContext';
import useFetch from '../../hooks/useFetch';
import AddPlan from '../Modal/AddPlan';
import CreateProfile from '../Modal/CreateProfile';

function Plans(props) {
    const { data } = props
    let path = useLocation()
    const myRef = useRef(null);

    const [tab, setTab] = useState('sign in')

    // Context
    const gotoPlan = useContext(RegisterContext).gotoPlan
    const setGotoPlan = useContext(RegisterContext).setGotoPlan
    const addPlan = useContext(RegisterContext).addPlan
    const setAddPlan = useContext(RegisterContext).setAddPlan
    const modalChangeLocation = useContext(LocationContext).setModal

    const [planInfo, setPlanInfo] = useState()
    const [modal, setModal] = useState<boolean>(false)
    const [planSelected, setPlanSelected] = useState<number | null>(0)
    const { data: membershipData } = useFetch({ params: 'membership-plans?sort=price', populate: 'image,features,memberId,position' })


    // Jumping on the classes info 
    const handleScroll = () => {
        const element = document.getElementById('membership-plans');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
            setGotoPlan(false)
        }
    };

    // Member Select Plan
    const handleAddPlan = () => {
        setModal(false)
        setAddPlan(true)
    }

    const handlleRegister = () => {
        if (localStorage.getItem('studio') == null) {
            modalChangeLocation(true)
        } else {
            setModal(true)
        }
    }

    useEffect(() => { path?.state?.isScroll && handleScroll() }, [])

    useEffect(() => {
        modal ? document?.body?.classList?.add('hide-scroll-mbo') : document?.body?.classList?.remove('hide-scroll-mbo')
    }, [modal])

    useEffect(() => {
        if (path.pathname == '/memberships' && gotoPlan) {
            handleScroll()
        }
    }, [gotoPlan])


    const sortedData = membershipData?.data?.sort((a, b) => a?.attributes?.position - b?.attributes?.position);

    return (
        <div className='bg-white lg:pt-[85px] sm:w-full w-[90%] mx-auto md:pt-[70px] pt-[45px] md:pb-[120px] pb-[80px]'>
            <div className='max-w-[813px] mx-auto'>
                <h1 className='text-black heading  text-center'>{data?.data?.attributes?.introduce?.title}</h1>
                <p className='md:mt-[54px] mt-7 heading3 text-black text-center '>{data?.data?.attributes?.introduce?.content}</p>
            </div>
            <div id='membership-plans' className='md:mt-[84px] mt-12 horizontalScroll pb-[55px] md:w-full flex lg:w-[1138px] w-full mx-auto gap-6'>
                {sortedData?.map((item: object, idx: number) => (
                    <div
                        ref={myRef}
                        onMouseLeave={() => setPlanSelected(null)} onMouseEnter={() => setPlanSelected(idx)}
                        key={idx}
                        className={`${idx == planSelected ? `bg-lilac transition-all` : `bg-white transition-all  `}
                        transition-all rounded-md lg:min-w-[360px] min-w-[245px] relative cursor-pointer pt-6 px-6  border border-lilac `}>
                        <div className='flex items-center  justify-between'>
                            <h6 style={{ fontWeight: 400 }} className='subheading'>{item?.attributes?.title}</h6>
                            {item?.attributes?.popular && (
                                <span className='bg-flexi w-[157px] h-[34px] uppercase subheading3 flex items-center justify-center rounded-[50px]'>popular</span>
                            )}
                        </div>
                        <div className='mt-[27px] rounded-md w-full overflow-hidden lg:h-[297px] h-[160px]'>
                            <img className='w-full' src={item?.attributes?.image?.data?.[0]?.attributes?.url} alt="yoga" />
                        </div>
                        <h5 style={{ fontWeight: 400 }} className='mt-6 subheading mb-6'>${item.attributes?.price}
                            {item?.attributes?.type === 'weekly' && '/week'
                                || item?.attributes?.type === 'monthly' && '/month'
                                || item?.attributes?.type === 'yearly' && '/year'
                                || item?.attributes?.type === 'class' && '/class'}
                        </h5>
                        <ul className='flex flex-col pb-[21px] gap-3'>
                            {item?.attributes?.features?.data?.map((e: string, idx: number) => (
                                <div key={idx}>
                                    <li className='flex items-center gap-4'><svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
                                        <path d="M1.5 13L6.79289 7.70711C7.12623 7.37377 7.29289 7.20711 7.29289 7C7.29289 6.79289 7.12623 6.62623 6.79289 6.29289L1.5 1" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg> <span className='heading3'>{e?.attributes?.title}</span></li>
                                </div>
                            ))}
                        </ul>
                        {idx == planSelected && (
                            <div className='bg-lilac mt-4 pb-7 relative top-0 left-0 rounded-bl-md rounded-br-md w-full '>
                                <button onClick={() => { handlleRegister(); setPlanInfo(item) }} className='w-full h-[40px] hover:bg-transparent border border-transparent hover:border-black bg-flexi subheading3 rounded-[6px] uppercase '>select plan</button>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* Modal Signup Membership */}
            {modal && (
                <div style={{ backgroundColor: 'rgba(0, 0, 0, .4)', zIndex: 999999999 }} className='fixed w-full  h-full z-40 left-0 top-0  border border-gray  rounded-md'>
                    <div style={{ zIndex: 99999999999 }} className='relative z-50 lg:w-[850px] w-full h-[90vh]  bg-white left-1/2 transformt rounded-md -translate-x-1/2 -translate-y-1/2 top-1/2'>
                        <div className='pt-16 px-10 md:flex hidden mx-auto  items-center justify-between'>
                            <CreateProfile />
                            <div className='-mt-12 '>
                                <h2 style={{ fontSize: 30 }} className='heading3 text-center'>Create an account</h2>
                                <p className='heading3 text-center mb-[30px]'>Please create an account to continue</p>
                                <div onClick={handleAddPlan} className='mx-auto w-full flex justify-center'>
                                    <Active text='sign up' />
                                </div>
                            </div>
                        </div>
                        {/* Mobile */}
                        <div className='md:hidden block'>
                            <div className='flex flex-col  pt-[50px]'>
                                <div className='flex items-start'>
                                    <div onClick={() => setTab('sign in')} className='ml-2'>
                                        <Active text='sign in' />
                                    </div>
                                    <div onClick={() => setTab('sign up')} className='ml-5'>
                                        <Active text='sign up' />
                                    </div>
                                </div>
                                <div className='mt-[20px]'>
                                    {tab == 'sign in'
                                        ? <CreateProfile />
                                        : <div className='min-h-full mt-5' >
                                            <h2 style={{ fontSize: 30 }} className='heading3 text-center'>Create an account</h2>
                                            <p className='heading3 text-center mb-[30px]'>Please create an account to continue</p>
                                            <div onClick={handleAddPlan} className='mx-auto w-full flex justify-center'>
                                                <Active text='sign up' />
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                        <div
                            onClick={() => setModal(false)}
                            style={{ zIndex: 999999999999999 }}
                            className='absolute cursor-pointer right-4 top-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M19 5L5 19" stroke="#545459" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5 5L19 19" stroke="#545459" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                </div>
            )}
            {/* Modal Select Plan */}
            {addPlan && <AddPlan info={planInfo?.attributes?.memberId} />}
        </div>
    )
}

export default Plans