import React from "react";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import { Link } from 'gatsby'
function SharePassionMovement(props) {
  const { data } = props
  return (
    <div className="bg-no-repeat fullscreen-supported" style={{ backgroundImage: `url(${data?.data?.attributes?.banner_2?.background?.data?.attributes?.url})` }}>
      <div className="lg:w-[920px] w-[90%] mx-auto flex flex-col gap-6 py-[238px] text-white">
        <p className="heading text-center">
          {data?.data?.attributes?.banner_2?.title}
        </p>
        <p className="body  text-center">
          {data?.data?.attributes?.banner_2?.description}
        </p>
        <div className="">
          <Link to='/classes'>
            <ButtonNavigate width='w-max mx-auto' labelText="CHECK OUR CLASSES" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SharePassionMovement;
