export const questions = [
    {
        name: 'How much does it cost to open a Rebalance studio?',
        answer: ['Investment starts from $175,000, including cost of territory.'],
    },
    {
        name: 'How much does it cost to secure a territory?',
        answer: ['A Rebalance territory costs $50,000.'],
    },
    {
        name: 'What size is a Rebalance studio?',
        answer: [
            'Studio size can range from 160sqm to 300sqm, variation dependent on number of rooms, and number of reformers.',
        ],
    },
    {
        name: 'How many reformers does each studio have?',
        answer: ['Minimum reformer requirement per studio is 16 beds.'],
    },
    {
        name: 'What is the Breakeven for a Rebalance studio?',
        answer: [
            'Breakeven numbers are different for each studio, dependent on overheads such as rent, utilities, and wages',
        ],
    },
    {
        name: 'How much is a Rebalance membership?',
        answer: ['Memberships range from $45 to $65 per week. Single visits and Class packs are also available.'],
    },
    {
        name: 'What franchise training is included in the initial investment?',
        answer: [
            'The upfront investment includes a comprehensive training program for all new franchise owners. The program is a 4-day face to face intensive, covering all areas to start and run your business successfully.',
        ],
    },
    {
        name: 'What support do I receive as a franchisee?',
        answer: [
            'Each franchisee has a dedicated Business Performance Manager (BPM) who specializes in business operations and performance. As well as offering a comprehensive support structure, BMP’s are available for as much or as little communication as required by the franchisee.',
        ],
    },
    {
        name: 'Where are territories available?',
        answer: ['Territories are currently available across QLD, NSW, VIC, ACT and WA.'],
    },
    {
        name: 'How many existing Rebalance studios are there?',
        answer: ['Rebalance currently has 8 studios in operation, across QLD, NSW, VIC and ACT.'],
    },
    {
        name: 'What is the franchise term?',
        answer: [
            'The term granted under the Rebalance franchise agreement is 5 years with an optional right to renew the agreement for an additional 5 years.',
        ],
    },
    {
        name: 'Who is Viva Leisure?',
        answer: [
            'Founded in 2004, Viva Leisure, is a locally owned, leading health and fitness club provider which became the first health club to IPO on the Australian Stock Exchange in June 2019. Our ASX ticker is VVA. The only health club group to operate in all four segments of the fitness market (Express, Standard, Big Box and Boutique), Viva offers a unique experience to it’s members and specialises in technology to enhance the fitness experience. Viva acquired the Rebalance brand in early 2021. Collectively, we’re the owner-operators and master franchisor of over 330 facilities across the Asia-Pacific region. That means that you have 20 years of experience and proven success to lean on.',
        ],
    },
];
