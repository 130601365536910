import React from 'react'
import ShapeInfo from '../../assets/images/Shape/ShapeInfo'

function ShareInteresting(props) {
  const { data } = props

  return (
    <div className='bg-agate py-[76px] flex items-center justify-center flex-col mx-auto'>
      <h2 className='heading text-center lg:w-[569px] w-full mb-[38px]'>{data?.data?.attributes?.banner2?.customMetadata?.heading}</h2>
      <div>
        <div
          className={`relative lg:w-[856px] md:w-[70%] w-[90%] h- mx-auto  overflow-hidden  `}>
          <ShapeInfo color="#C5C5FB" />
          <img className="absolute object-cover w-full h-full object-center top-0" src={data?.data?.attributes?.banner2?.background?.data?.attributes?.url} />
        </div>
      </div>
      <div className='mt-[32px] flex flex-wrap items-center lg:gap-[100px] gap-[40px] justify-center'>
        {data?.data?.attributes?.banner2?.customMetadata?.description?.map((item, idx) => (
          <div key={idx} className='flex items-center justify-center flex-col'>
            <h1 className='heading text-black uppercase font-bold'>{item?.value}</h1>
            <span className='mt-[22px] uppercase text-black subheading3'>{item?.text}</span>
          </div>
        ))}
      </div>

    </div>
  )
}

export default ShareInteresting