import React, { useState, createContext, useEffect } from "react";
import useFetch from "../hooks/useFetch";

const LocationContext = createContext<any>(null)
interface iProps {
    children: React.ReactNode;
}

const LocationProvider = ({ children }: iProps) => {
    const [param, setParam] = useState<string>("");
    const [modal, setModal] = useState<boolean>(false)
    const [currentPos, setcurrenPos] = useState({ lat: null, lng: null })
    const [yourClub, setYourClub] = useState()

    // Get studio near you
    const { data: nearYou, } = useFetch({ params: param, populate: "image,state" });
    useEffect(() => {
        const getStudios = () => {
            navigator?.geolocation?.getCurrentPosition((position) => {
                setParam(`studios/inRange?location[]=${position?.coords?.latitude}&location[]=${position?.coords?.longitude}&distance=10000000`);
                setcurrenPos({ ...currentPos, lat: position?.coords?.latitude, lng: position?.coords?.longitude })
            });
        };
        getStudios();
    }, []);


    // Get state of user if user the has accepted to get the location
    useEffect(() => {
        if (currentPos.lat !== null && nearYou?.data?.length > 0 && localStorage.getItem('isGetLocation') == null) {
            localStorage.setItem('isGetLocation', "ok")
            localStorage.setItem('studio', JSON.stringify(nearYou?.data?.pop()?.attributes?.state?.Name))
            setYourClub(JSON.parse(localStorage.getItem('studio')))
        }
    }, [currentPos?.lat, nearYou, yourClub])


    return (
        <LocationContext.Provider value={{ modal, setModal, param, nearYou, currentPos, yourClub, setYourClub }}>
            {children}
        </LocationContext.Provider>
    )
}

export { LocationContext, LocationProvider }
