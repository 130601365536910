import React from 'react';

import { questions } from '../../mock/ownQuestion';
import FlexiblePlan from '../components/HomePage/FlexiblePlan';
import FreePass from '../components/HomePage/FreePass';
import HeroBanner from '../components/HomePage/HeroBanner';
import Layout from '../components/layout/Layout';
import OwnARebalance from '../components/own-a-studio/OwnARebalance';
import OwnYoga from '../components/own-a-studio/OwnYoga';
import SharePassionMovement
  from '../components/own-a-studio/SharePassionMovement';
import TextAnimation from '../components/own-a-studio/TextAnimation';
import useFetch from '../hooks/useFetch';
import ErrorBoundary from '../utils/ErrorBoundary';

function ownAStudio() {
  const { data } = useFetch({
    params: 'own-a-studio',
    populate: 'banner_1.background,banner_1.mobileImage,text_group_1,register_form_group,banner_2.background,banner_3.background,free_pass_banner.background'
  })
  return (
    <ErrorBoundary>
      <Layout>
        <HeroBanner
          title={data?.data?.attributes?.banner_1?.title}
          bannerMobile={data?.data?.attributes?.banner_1?.mobileImage?.data?.attributes?.url}
          img={data?.data?.attributes?.banner_1?.background?.data?.attributes?.url} />
        <TextAnimation />
        <OwnYoga data={data} />
        <OwnARebalance data={data} />
        <FlexiblePlan
          color='#FFFDEF'
          isShared={true}
          isHideButton={true}
          background={data?.data?.attributes?.banner_3?.background?.data?.attributes?.url}
          description={data?.data?.attributes?.banner_3?.customMetadata?.description}
          title={data?.data?.attributes?.banner_3?.title}
          classInfo={data?.data?.attributes?.banner_3?.customMetadata?.classInfo}
        />
        {/* Questions  */}
        <>
          <div className="my-[82px] details-tag-after  max-w-[800px] mx-6 lg:mx-auto">
            <div className="w-full">
              <p className="heading text-center text-black">
                All your questions answered
              </p>
              <p className="body text-center text-black max-w-[674px] mx-auto my-[54px]">
                We have compiled a list of questions to help make the Rebalance® experience a little easier. If you can't find the answer you're looking for, please feel free to contact us and we'll be happy to help.
              </p>
            </div>
            <h2
              className="uppercase text-black subheading3 mb-7"
              style={{ fontSize: "20px" }}
            >
              Franchise FAQ’s
            </h2>
            {questions.map((i, index) => (
              <details key={index} className="border-t border-t-lemonadeSecondary  first:border-t first:border-t-lemonadeSecondary last:border-b last:border-b-lemonadeSecondary text-black rounded-[5px] py-6 pl-12 pr-6 outline-none  ">
                <summary
                  style={{ fontSize: "18px" }}
                  className="subheading pl-6 cursor-pointer"
                >
                  {i.name}
                </summary>
                <p className=" pt-[18px] text-black body  pl-6">{i.answer}</p>
              </details>
            ))}

          </div>
        </>
        <SharePassionMovement data={data} />

        {data?.data?.attributes?.free_pass_banner?.background?.data?.attributes?.url && (
          <FreePass
            background='#CEE3C4'
            isWhite={false}
            img={data?.data?.attributes?.free_pass_banner?.background?.data?.attributes?.url}
            desc={data?.data?.attributes?.free_pass_banner?.description} />
        )}
      </Layout>
    </ErrorBoundary >
  );
}

export default ownAStudio;
