import React from 'react'
import HeroBanner from "../../components/HomePage/HeroBanner";
function Banner(props) {
    const { data } = props
    return (
        <div>
            <HeroBanner
                img={data?.data?.attributes?.banner1?.background?.data?.attributes?.url}
                title={data?.data?.attributes?.banner1?.title}
                bannerMobile={data?.data?.attributes?.banner1?.mobileImage?.data?.attributes?.url}
            />
            <div className="bg-jade studio-banner">
                <div className=" border-l-0 border-r-0 overflow-hidden px-2 py-[30px] relative ">
                    <div className="flex top-1/2 transform -translate-y-1/2 absolute  text-[40px] text-turf">

                        <div className="flex w-[1440px] lg:gap-[80px] gap-8 items-center logo">
                            <span>
                                <p className="subheading font-bold whitespace-nowrap">barre</p>
                            </span>
                            <span>
                                <p className="subheading font-bold whitespace-nowrap">
                                    pilates
                                </p>
                            </span>
                            <span>
                                <p className="subheading font-bold whitespace-nowrap">yoga</p>
                            </span>
                            <span>
                                <p className="subheading font-bold whitespace-nowrap">
                                    reformer
                                </p>
                            </span>
                            <span>
                                <p className="subheading font-bold whitespace-nowrap">barre</p>
                            </span>
                            <span>
                                <p className="subheading font-bold whitespace-nowrap">
                                    pilates
                                </p>
                            </span>
                            <span>
                                <p className="subheading font-bold whitespace-nowrap">yoga</p>
                            </span>
                            <span>
                                <p className="subheading font-bold whitespace-nowrap">
                                    reformer
                                </p>
                            </span>
                        </div>
                        <div className="flex w-[1440px] gap-[80px] items-center logo">
                            <span>
                                <p className="subheading font-bold whitespace-nowrap">barre</p>
                            </span>
                            <span>
                                <p className="subheading font-bold whitespace-nowrap">
                                    pilates
                                </p>
                            </span>
                            <span>
                                <p className="subheading font-bold whitespace-nowrap">yoga</p>
                            </span>
                            <span>
                                <p className="subheading font-bold whitespace-nowrap">
                                    reformer
                                </p>
                            </span>
                            <span>
                                <p className="subheading font-bold whitespace-nowrap">barre</p>
                            </span>
                            <span>
                                <p className="subheading font-bold whitespace-nowrap">
                                    pilates
                                </p>
                            </span>
                            <span>
                                <p className="subheading font-bold whitespace-nowrap">yoga</p>
                            </span>
                            <span>
                                <p className="subheading font-bold whitespace-nowrap">
                                    reformer
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
            </div></div>
    )
}

export default Banner