import React, {
    useContext,
    useEffect,
    useState,
} from 'react';

import { Link } from 'gatsby';

import { useLocation } from '@reach/router';

import FreePassShape from '../../assets/images/Shape/FreePassShape';
import Active from '../../common/buttons/Active';
import DropdownBase from '../../common/dropdowns/DropdownBase';
import InputBase from '../../common/inputs/RebalanceInputBase';
import { LocationContext } from '../../context/LocationContext';
import useFetch from '../../hooks/useFetch';
import useScrollToGetHeight from '../../hooks/useScroll';
import {
    FreePassToStrapiAPI,
    GetFreePassAPI,
} from '../../utils/FetchData';
import Succes from '../Modal/Succes';
import BookingClass from './BookingClass';

function Clubs(props) {
    const path = useLocation()
    const { data } = props
    const [tab, setTab] = useState<string>('')
    const [classes, setClasses] = useState<object>()
    const [booking, setBooking] = useState<boolean>(false)
    const [tabMoused, setTabMoused] = useState<string>('')

    const { scrollPosition } = useScrollToGetHeight()
    const { data: classInfo } = useFetch({ params: 'class-categories', populate: 'image,shape,classes_types,classes_types.classInfo,classes_types.howYouFeel,position' })

    // Location + Modal Keepme
    const [status, setStatus] = useState<boolean>(false)
    const { data: locations } = useFetch({ params: 'studios', populate: 'state' })

    // Context
    const [state, setState] = useState('')
    const setModal = useContext(LocationContext).setModal
    // User Info Keepme
    const [userInfo, setUserInfo] = useState({
        venue_id: "1234", first_name: "", last_name: "", email: "", phone: "", city: "", state: "", interested_in: "Fitness, Personal Training", user_id: "1234", referred_by: "Friend", venue_name: "", "Source Group": "Website",
        "Source Name": "Free Class",
    })

    //Get Class selected
    useEffect(() => {
        let filter = classInfo?.data?.filter(item => item?.attributes?.name.toLowerCase() == tab)
        setClasses(filter)
    }, [tab]);

    // Jumping on the classes info 
    const handleClickScroll = () => {
        const element = document.getElementById('class-info');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
        }
    };

    // Handle change value input
    const handleChangeValue = (e) => {
        const value = e.target.value
        setUserInfo((prevState) => ({ ...prevState, [e.target.name]: value }))
    }

    // Get state from studio
    useEffect(() => {
        let state = locations?.data?.filter(item => item?.attributes?.title == userInfo.city)?.[0]?.attributes?.state?.data?.attributes?.Name
        setUserInfo({ ...userInfo, state: state, venue_name: userInfo.city })
    }, [userInfo.city])

    // Cleanup Data after submit
    useEffect(() => {
        if (!status) {
            setUserInfo({
                venue_id: "1234", first_name: "", last_name: "", email: "", phone: "", city: "", state: "", interested_in: "Fitness, Personal Training", user_id: "1234", referred_by: "Friend", venue_name: "", "Source Group": "Website",
                "Source Name": "Free Class",
            })
        }
    }, [status])

    // Hide body scrollbar + overlay when MBO loaded
    useEffect(() => {
        booking ? document?.body?.classList.add('hide-scroll-mbo') : document?.body?.classList?.remove('hide-scroll-mbo')
    }, [booking])

    // Handle Submit
    const handleSubmit = async () => {
        const emptyFields = Object.entries(userInfo).filter(([key, value]) => value === '');
        if (emptyFields.length > 0) {
            const fieldNames = emptyFields.map(([key, value]) => key).join(', ');
            alert(`The following fields are required: ${fieldNames}`);
        } else {
            await GetFreePassAPI(userInfo);
            await FreePassToStrapiAPI(userInfo)
            setStatus(true)
        }
    }

    // Get State
    useEffect(() => { setState(JSON.parse(localStorage.getItem('studio'))) }, [localStorage.getItem('studio')])

    const handleBooking = () => {
        if (localStorage.getItem('studio') !== null) {
            setBooking(true)
        } else {
            setModal(true)
        }
    }

    // Check Auto Selected Class by prev Page
    useEffect(() => {
        if (path?.state?.classSelected !== '' && path?.state?.classSelected) {
            handleClickScroll()
            setTab(path?.state?.classSelected?.toLowerCase())
            setClasses(classInfo?.data?.filter(item => item?.attributes?.name.toLowerCase() == tab))
        }
    }, [path?.state?.classSelected, classInfo])




    return (
        <>
            {/* Modal Book Classes */}
            {booking && (
                <BookingClass
                    tab={tab}
                    booking={booking}
                    setBooking={setBooking}
                    state={state?.attributes?.state?.data?.attributes?.Name?.toUpperCase() || state.toUpperCase()}
                />
            )}
            <div >
                <div className='lg:w-[828px] w-[95%] md:py-[84px] py-[45px] mx-auto'>
                    <h2 className='heading text-center text-black sm:w-auto w-[332px] mx-auto'>{data?.data?.attributes?.introduce?.title}</h2>
                    <p className=' text-black heading3 text-center sm:mt-[54px] mt-[30px]'>{data?.data?.attributes?.introduce?.content}</p>
                </div>
                <div id='class-info' ></div>
                <div className='lg:block hidden'>
                    <div className={`grid cursor-pointer  transition-all lg:grid-cols-4 grid-cols-2 ${scrollPosition > 1700 ? 'fixed left-0 top-[108px] w-full z-50' : ''}  `}>
                        {classInfo?.data?.map((item: object, idx: number) => (
                            <div
                                onMouseEnter={() => setTabMoused(item?.attributes?.name)}
                                onClick={() => { setTab(item?.attributes?.name); handleClickScroll() }}
                                key={idx} className=' relative h-auto '>
                                <img className={`w-full transition-all   ${scrollPosition > 1700 ? 'hidden transition-all' : 'block transition-all'} `}
                                    src={item?.attributes?.image?.data?.attributes?.url} />
                                {tab == item?.attributes?.name && (
                                    <img
                                        className={`absolute   top-0 transition-all left-0  object-cover  w-full  ${scrollPosition > 1700 ? 'hidden transition-all' : 'block transition-all'} `}
                                        src={item?.attributes?.shape?.data?.attributes?.url} />
                                )}
                                {tabMoused == item?.attributes?.name && (
                                    <img className={`absolute top-0 transition-all left-0 w-full  object-cover    ${scrollPosition > 1700 ? 'hidden transition-all' : 'block transition-all'}`} src={item?.attributes?.shape?.data?.attributes?.url} />
                                )}
                                <div style={{ backgroundColor: item?.attributes?.color?.color }} className={`absolute w-full  transition-all flex items-center feature justify-center text-white h-[116px]`}>
                                    <span className={`${tab == item?.attributes?.name ? 'club-active' : ''}`}>
                                        {item?.attributes?.name}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
            {/* Classes showing */}
            <div className='lg:mt-[115px] lg:block hidden mt-0 xxx:w-[1252px] w-full mx-auto'>
                {classes?.[0]?.attributes?.classes_types?.data?.sort((a, b) => b?.attributes?.position - a?.attributes?.position)?.map((item: object, idx: number) => (
                    <div key={idx} className='flex items-center xxx:flex-row flex-col w-full justify-between py-[42px]'>
                        <div className='xxx:w-1/2 w-[90%]'>
                            <h2 className='heading text-black'>{item?.attributes?.title}</h2>
                            <p className='my-[24px] xxx:w-[628px] w-full heading3 text-black'>{item?.attributes?.content}</p>
                            <div className='xxx:block  hidden'>
                                <div className=' w-[140px]' onClick={handleBooking}>
                                    <Active text='book now' />
                                </div>

                            </div>
                        </div>
                        <div className='xxx:w-1/2 w-[90%] flex items-center xxx:ml-[100px] ml-0 justify-between'>
                            <div>
                                <h6 className='uppercase subheading2 text-black'>class info</h6>
                                <ul className='mt-[20px] '>
                                    {item?.attributes?.classInfo?.map((singleClass: any, idx: number) => (
                                        <li key={idx} className='flex items-center py-1 gap-4'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="none">
                                                <path d="M1.00025 0.814704L7.68555 7.5L1.00025 14.1853" stroke="#4F6E6A" strokeWidth="1.5" />
                                            </svg>
                                            <span className='body text-black'>{singleClass?.title}</span>
                                        </li>
                                    ))}

                                </ul>
                            </div>
                            <div>
                                <h6 className='uppercase subheading2 text-black'>how you'll feel</h6>
                                <ul className='mt-[20px] '>
                                    {item?.attributes?.howYouFeel.map((feel: any, idx: number) => (
                                        <li key={idx} className='flex items-center py-1 gap-4'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="none">
                                                <path d="M1.00025 0.814704L7.68555 7.5L1.00025 14.1853" stroke="#4F6E6A" strokeWidth="1.5" />
                                            </svg>
                                            <span className='body text-black'>{feel?.title}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div onClick={handleBooking} className='xxx:hidden block w-[90%] mt-4 float-left'>
                            <Active text='book now' />
                        </div>
                    </div>
                ))}
            </div>

            {/* Class for mobile version */}
            <div className='lg:hidden block '>
                <div>
                    <div className={`flex flex-col `}>
                        {classInfo?.data?.map((item: object, idx: number) => (
                            <div key={idx} >
                                <div
                                    className='  cursor-pointer '>
                                    <div className='relative'>
                                        <img className={`w-full  z-40  object-cover transition-all smallTablet:h-[450px] h-[254px] `}
                                            src={item?.attributes?.image?.data?.attributes?.url} />
                                        <span className='absolute cursor-pointer z-20 top-[0px] right-0'>
                                            {
                                                item?.attributes?.name == tab
                                                    ? <div onClick={() => setTab('')} className='h-[80px]  flex items-center w-[120px] justify-center'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="5" viewBox="0 0 28 5" fill="none">
                                                            <path d="M27.1786 0.478526V4.73904H0.124377V0.478526H27.1786Z" fill="#FEFEFF" />
                                                        </svg>
                                                    </div>
                                                    : <div onClick={() => setTab(item?.attributes?.name)} className='h-[80px] w-[120px] flex justify-center items-center '>

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" viewBox="0 0 28 30" fill="none">
                                                            <path d="M11.3915 17.1296H0.451612V12.6249H11.45V0.749054H16.1886V12.6249H27.07V17.1296H16.1301V29.0055H11.3915V17.1296Z" fill="#FEFEFF" />
                                                        </svg>
                                                    </div>
                                            }

                                        </span>
                                        <p style={{ fontSize: '54px' }} className='subheading3 absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 text-white'>{item.name}</p>
                                        <div style={{ backgroundColor: item?.attributes?.color?.color }}
                                            className='h-[80px] flex !font-bold text-white capitalize subheading items-center justify-center w-full'>{item?.attributes?.name}</div>
                                    </div>
                                </div>
                                {/* {tab == item?.atrributes?.name && ( */}
                                <>
                                    {tab == item?.attributes?.name && (
                                        <>
                                            {classes?.[0]?.attributes?.classes_types?.data?.map((item: object, idx: number) => (
                                                <div key={idx} className='flex items-center bg-lilac border-b border-b-black flex-col w-full justify-between py-[42px]'>
                                                    <div className='xxx:w-1/2 w-[90%]'>
                                                        <h2 className='heading text-black'>{item?.attributes?.title}</h2>
                                                        <p style={{ fontSize: '16px' }} className='my-[24px] xxx:w-[628px] w-full heading3 text-black'>{item?.attributes?.content}</p>
                                                        <div onClick={handleBooking} className='xxx:block hidden'>
                                                            <Active text='book now' />
                                                        </div>
                                                    </div>
                                                    <div onClick={handleBooking} className='xxx:hidden block w-[90%] mt-4 float-left'>
                                                        <Active text='book now' />
                                                    </div>
                                                    <div className='xxx:w-1/2 w-[90%] flex mt-[50px] flex-col items-start xxx:ml-[100px] ml-0 justify-between'>
                                                        <div>
                                                            <h6 className='uppercase subheading2 text-black'>class info</h6>
                                                            <ul className='mt-[20px] '>
                                                                {item?.attributes?.classInfo?.map((singleClass: any, idx: number) => (
                                                                    <li key={idx} className='flex items-center py-1 gap-4'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="none">
                                                                            <path d="M1.00025 0.814704L7.68555 7.5L1.00025 14.1853" stroke="#4F6E6A" strokeWidth="1.5" />
                                                                        </svg>
                                                                        <span className='body text-black'>{singleClass?.title}</span>
                                                                    </li>
                                                                ))}

                                                            </ul>
                                                        </div>
                                                        <div>
                                                            <h6 className='uppercase subheading2 text-black mt-[70px]'>how you'll feel</h6>
                                                            <ul className='mt-[20px] '>
                                                                {item?.attributes?.howYouFeel?.map((feel: any, idx: number) => (
                                                                    <li key={idx} className='flex items-center py-1 gap-4'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="none">
                                                                            <path d="M1.00025 0.814704L7.68555 7.5L1.00025 14.1853" stroke="#4F6E6A" strokeWidth="1.5" />
                                                                        </svg>
                                                                        <span className='body text-black'>{feel?.title}</span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))}
                                        </>
                                    )}
                                </>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
            {/* Free Pass Sections */}
            <div className='w-full bg-agate  mx-auto '>
                <div className='flex lg:w-[1300px] w-[90%] mx-auto lg:flex-row flex-col items-center justify-between lg:py-[89px] py-[40px]'>

                    <div
                        className={`relative lg:w-[412px] md:w-[70%] w-[90%]`}>
                        <FreePassShape color={"#BBCCF3"} />
                        <img alt='shape' className="absolute object-cover w-full h-[calc(100%-3px)] top-[1px]" src={data?.data?.attributes?.freePassBanner?.background?.data?.attributes?.url} />
                    </div>
                    <div className='lg:w-[814px] w-full lg:mt-0 mt-8'>
                        <h2 className='heading text-black sm:mt-0 mt-4'>{data?.data?.attributes?.freePassBanner?.title}</h2>
                        <p className='mt-[18px] text-black heading3 '>{data?.data?.attributes?.freePassBanner?.description}</p>
                        <div className="grid md:grid-cols-2 grid-cols-1 col-span-5 mt-6 gap-x-3 gap-y-6">
                            <InputBase value={userInfo.first_name} onChange={handleChangeValue} nameInput="first_name" holder="First Name*" />
                            <InputBase value={userInfo.last_name} onChange={handleChangeValue} nameInput="last_name" holder="Last name*" />
                            <InputBase value={userInfo.email} onChange={handleChangeValue} nameInput="email" holder="Email address*" />
                            <InputBase value={userInfo.phone} onChange={handleChangeValue} nameInput="phone" holder="Phone Number*" />
                            <DropdownBase
                                holder="Location*"
                                color={"black"}
                                options={locations?.data}
                                location={userInfo.city}
                                setLocation={selectedLocation => setUserInfo({ ...userInfo, city: selectedLocation })}
                            />
                        </div>
                        <div className="mt-[48px] flex justify-end">
                            <div onClick={handleSubmit}>
                                <Active text="ENQUIRE NOW" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ backgroundImage: `url(${data?.data?.attributes?.membershipBanner?.background?.data?.attributes?.url})` }}
                className='lg:h-[680px] h-[400px] w-full flex items-center justify-center flex-col bg-cover bg-no-repeat'>
                <h1 className='heading lg:w-full w-[90%] text-white text-center'>{data?.data?.attributes?.membershipBanner?.title}</h1>
                <p className='my-[24px] lg:w-auto w-[90%] text-white heading3 text-center'>
                    {data?.data?.attributes?.membershipBanner?.description}
                </p>
                <Link to='/memberships'>
                    <Active text='find out more' />
                </Link>
            </div>
            {status && <Succes open={status} setOpen={setStatus} />}

        </>
    )
}

export default Clubs