
import React, { useEffect, useContext, useState } from 'react'
import { RegisterContext } from '../../context/RegisterContext'

function CreateProfile() {
    const setRegister = useContext(RegisterContext).setRegister

    const [state, setState] = useState()

    useEffect(() => {
        let currentState = JSON.parse(localStorage.getItem('studio'))
        if (typeof currentState == 'object') {
            setState(currentState?.attributes?.state?.data?.attributes?.Name)
        } else {
            setState(currentState)
        }
    }, [localStorage.getItem('studio')])

    return (
        <div onClick={() => setRegister(false)} className='h-[90vh]'>
            {/* <div style={{ zIndex: 999999999999999 }}
                className='fixed  w-full rounded-lg overflow-hidden left-1/2 h-[100vh] top-1/2  transform -translate-x-1/2 -translate-y-1/2    z-40'>
                <div className='lg:w-[768px] w-full mx-auto mt-[40px] rounded-tl-md rounded-tr-md border-b relative border-b-gray h-[70px] bg-[#F8F9FA]'>
                    <div
                        style={{ zIndex: 999999999999999 }}
                        className='absolute cursor-pointer right-4 top-4'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M19 5L5 19" stroke="#545459" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5 5L19 19" stroke="#545459" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div> */}
            <iframe id="mindbody_branded_web_cart_modal"
                className='h-[86%]  horizontalScroll rounded-bl-lg rounded-br-lg mx-auto  w-[400px]'
                src={`https://cart.mindbodyonline.com/sites/${state == 'NSW' && '106809' || state == 'ACT' && '110588' || state == 'VIC' && '12000' || state == 'QLD' && '10332'}/client?source=account-link`}
            // src={`https://cart.mindbodyonline.com/sites/${state == 'NSW' && '106809' || state == 'QLD' && '10332' || state == 'ACT' && '106809' || state == 'VIC' && '12000'}/client/new`}
            ></iframe>
            {/* </div> */}
        </div>
    )
}

export default CreateProfile