import React from "react";

function MoreThanWorkout(props) {
  const { data } = props
  return (
    <div className="flex flex-col justify-center items-center lg:py-[82px] py-[30px]  lg:w-[800px] w-[90%] mx-auto gap-[75px]">
      <div className="py-[43px] justify-between flex flex-col ">
        <p className="heading text-center">
          {data?.data?.attributes?.introduce?.title}
        </p>
        <p className="body text-center mt-[54px]">
          {data?.data?.attributes?.introduce?.content}
        </p>
      </div>

    </div>
  );
}

export default MoreThanWorkout;
