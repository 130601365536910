import React, {
  useEffect,
  useState,
} from 'react';

import { HeadFC } from 'gatsby';
import { Helmet } from 'react-helmet';

import {
  Router,
  useLocation,
} from '@reach/router';

import PrivateRoute from '../components/PrivateRoute';
import { LocationProvider } from '../context/LocationContext';
import { StoreProvider } from '../context/RegisterContext';
import useFetch from '../hooks/useFetch';
import about from '../routes/about';
import BlogDetail from '../routes/blog/BlogDetail';
import classes from '../routes/classes';
import contact from '../routes/contact';
import Faq from '../routes/Faq';
import HomePage from '../routes/index';
import legal from '../routes/legal';
import membership from '../routes/membership';
import MovementChallenge from '../routes/MovementChallenge';
import NewMembership from '../routes/New-Membership';
import ownAStudio from '../routes/ownAStudio';
import Studio from '../routes/studio/Studio';
import StudioDetail from '../routes/studio/StudioDetail';
import timetable from '../routes/timetable';
import WorkWithUs from '../routes/work-with-us';
import { SEO } from '../SEO/Seo';

const IndexPage = () => {
  return (
    <StoreProvider>
      <LocationProvider>
        <Helmet>
          <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TZ5VJ6B"
            height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>`}</noscript>
          <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TZ5VJ6B');
        `}</script>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-2XRWVJR5R4"></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-2XRWVJR5R4');
            `}
          </script>
        </Helmet>

        <Router basepath="/">
          <PrivateRoute path="/" component={HomePage} />
          <PrivateRoute path="/memberships" component={membership} />
          <PrivateRoute path="/classes" component={classes} />
          <PrivateRoute path="/own-a-studio" component={ownAStudio} />
          <PrivateRoute path="/legal" component={legal} />
          <PrivateRoute path="/about" component={about} />
          <PrivateRoute path="/timetable" component={timetable} />
          <PrivateRoute path="/movement-challenge" component={MovementChallenge} />
          <PrivateRoute path='/own-a-studio' component={ownAStudio} />
          <PrivateRoute path='/about' component={about} />
          {/* <PrivateRoute path='/blogs' component={blogs} /> */}
          <PrivateRoute path='/blog/:slug' component={BlogDetail} />
          <PrivateRoute path='/studios' component={Studio} />
          <PrivateRoute path='/faq' component={Faq} />
          <PrivateRoute path='/studio/:id' component={StudioDetail} />
          <PrivateRoute path='/work-with-us' component={WorkWithUs} />
          <PrivateRoute path='/new-membership' component={NewMembership} />
          <PrivateRoute path='/contact' component={contact} />
        </Router>
      </LocationProvider>
    </StoreProvider>
  );
};

export default IndexPage;

export const Head: HeadFC = () => {
  const path = useLocation()
  const [content, setContent] = useState<string>('')
  const { data: seo } = useFetch({ params: 'global', populate: 'seo' })

  useEffect(() => {
    if (path.pathname.replace(/\//g, "") == '') {
      setContent(seo?.data?.attributes?.seo?.[0])
    } else {
      setContent(seo?.data?.attributes?.seo?.find(item => item?.key == path.pathname.replace(/\//g, "")))
    }
  }, [seo, path.pathname])
  return (
    <SEO title={'Rebalance'} description={content?.value}></SEO>
  )
}
