import React from "react";
import Layout from "../components/layout/Layout";
import HeroBanner from "../components/HomePage/HeroBanner";
import hero_banner from "../assets/images/legal/hero_banner.png";
import TextAnimation from "../components/own-a-studio/TextAnimation";
import LegalInfo from "../components/legal/LegalInfo";
import useFetch from "../hooks/useFetch";
import ErrorBoundary from "../utils/ErrorBoundary";
function legal() {
  const { data, loading } = useFetch({
    params: "legal",
    populate:
      "topBanner, topBanner.image,topBanner.mobileImage, legalTitle, legalDescription, policy",
  });
  return (
    <ErrorBoundary>
      <Layout>
        <HeroBanner
          bannerMobile={data?.data?.attributes?.topBanner?.mobileImage?.data?.attributes?.url}
          title={data?.data?.attributes?.topBanner?.title || "Legal"}
          img={data?.data?.attributes?.topBanner?.image.data.attributes.url || hero_banner}
        />
        <TextAnimation />
        <LegalInfo
          policy={data?.data?.attributes?.policy}
          firstSection={{ title: data?.data?.attributes?.legalTitle, desc: data?.data?.attributes?.legalDescription }}
        />
      </Layout>
    </ErrorBoundary>
  );
}

export default legal;
