import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import { navigate } from 'gatsby';

import location from '../../assets/svg/location.svg';
import { LocationContext } from '../../context/LocationContext';
import { RegisterContext } from '../../context/RegisterContext';
import useFetch from '../../hooks/useFetch';

function Studios(props) {
  const {onOpenFreePass} = props
  const [locations, setLocations] = useState<string[]>([]);
  const { data } = useFetch({ params: "studios", populate: "background,studio_features,state" });

  const [isMap, setIsMap] = useState<boolean>(false);

  const [studioData, setStudioData] = useState<object[]>([]);
  const [searchData, setSearchData] = useState<object[]>([]);

  const [isModalFreePast, setIsModalFreePast] = useState(false);
  // Context
  const setModal = useContext(LocationContext).setModal;
  const setSelectedStudio = useContext(RegisterContext).setSelectedStudio

  // Get Studio Data
  useEffect(() => {
    locations.length == 0 && setLocations(["ACT", "QLD", "NSW", "VIC"]);
  }, [locations]);

  useEffect(() => {
    setStudioData(data?.data);
    setSearchData(data?.data);
  }, [data]);

  function searchByCriteria(criteria: string) {
    const result = studioData?.filter((item) => {
      const titleMatch = item?.attributes?.title?.toLowerCase()?.includes(criteria?.toLowerCase());
      const stateMatch = item?.attributes?.state?.data?.attributes?.Name?.toLowerCase()?.includes(
        criteria?.toLowerCase()
      );
      return titleMatch || stateMatch;
    });
    criteria === "" ? setSearchData(studioData) : setSearchData(result);
  }

  const handleJoinMembership = (name: string) => {
    switch (name) {
      case "Margate":
        window.open(
          "https://clients.mindbodyonline.com/classic/ws?studioid=134099&stype=40&prodId=199",
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "Rocklea":
        window.open(
          "https://clients.mindbodyonline.com/classic/ws?studioid=134099&stype=40&prodId=199",
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "Tuggeranong":
        window.open(
          "https://clients.mindbodyonline.com/classic/ws?studioid=5728884&stype=40&prodId=121",
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "Carina":
        window.open(
          "https://clients.mindbodyonline.com/classic/ws?studioid=134099&stype=40&prodId=199",
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "Mansfield":
        window.open(
          "https://clients.mindbodyonline.com/classic/ws?studioid=134099&stype=40&prodId=199",
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "Kedron":
        window.open(
          "https://clients.mindbodyonline.com/classic/ws?studioid=134099&stype=40&prodId=199",
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "Glen Waverley":
        window.open(
          "https://clients.mindbodyonline.com/classic/ws?studioid=236901&stype=40&prodId=122",
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "Hamilton North":
        window.open(
          "https://clients.mindbodyonline.com/classic/ws?studioid=632579&stype=40&prodId=142",
          "_blank",
          "noopener,noreferrer"
        );
        break;

      default:
        break;
    }
  };

  return (
    <div className='w-full'>
        {/* Studios + Map */}
        <p style={{ fontSize: 32 }} className='flex items-center justify-center heading '>
          Find a studio
        </p>
        <div className='flex items-center mt-8 mx-5 gap-2 h-[40px] border border-[#ccc] px-3 rounded-md'>
          <svg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0.680908 8.32294C0.680908 12.9146 4.61076 16.6459 9.43772 16.6459C11.6613 16.6459 13.6951 15.8513 15.2425 14.5487L20.8231 19.8529C20.9266 19.9512 21.0602 20 21.1979 20C21.3356 20 21.4693 19.9512 21.5727 19.8529C21.7797 19.6561 21.7797 19.3409 21.5727 19.1442L15.988 13.8401C17.3585 12.3694 18.1945 10.4403 18.1945 8.32294C18.1945 3.73127 14.2639 0 9.43772 0C4.61157 0 0.680908 3.73127 0.680908 8.32294ZM17.1385 8.32294C17.1385 12.3578 13.6821 15.6422 9.43772 15.6422C5.19335 15.6422 1.73691 12.357 1.73691 8.32294C1.73691 4.28887 5.19253 1.00368 9.43772 1.00368C13.6829 1.00368 17.1385 4.28422 17.1385 8.32294Z'
              fill='black'
            />
          </svg>
          <input
            onChange={(e) => searchByCriteria(e.target.value)}
            className='w-full outline-none border-none h-full'
            placeholder='Search by city, state or postcode'
            type='text'
          />
        </div>
        <div className={`flex items-center justify-between gap-8  `}>
          {/* Studios */}
          <div
            className={`pr-2 lg:flex hidden  overflow-y-scroll horizontalScroll-map gap-y-6   flex-col ${
              !isMap ? "opacity-0 h-0" : "opacity-100  h-[700px]"
            } `}
          >
            {searchData?.map((item, idx) => (
              <div key={idx}>
                <div className='p-6 rounded-[6px] gap-5 bg-lilac flex items-center justify-between'>
                  <div className='w-[280px] h-[155px] rounded-[6px] overflow-hidden'>
                    <img
                      src={item?.attributes?.background?.data?.attributes?.url}
                      className='w-full h-full object-cover'
                    />
                  </div>
                  <div className='w-[280px]'>
                    <h2 className='subheading3 text-black' style={{ fontSize: "20px" }}>
                      {item?.attributes?.title}
                    </h2>
                    <div
                      onClick={() =>
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${item?.attributes?.location?.coordinates?.[1]},${item?.attributes?.location?.coordinates?.[0]}`,
                          "_blank"
                        )
                      }
                      className='flex gap-[10px] my-[18px] items-center '
                    >
                      <img className='w-[15px]' src={location} />
                      <p className='heading3 cursor-pointer hover:underline text-black'>
                        {item?.attributes?.fullAddress}
                      </p>
                    </div>
                    <div className='flex flex-col gap-[13px]'>
                      {item?.attributes?.studio_features?.data
                        ?.sort((a, b) => a?.attributes?.priority - b?.attributes?.priority)
                        ?.map((fe, idx) => (
                          <div key={idx} className='flex pl-1 items-center gap-[13px]'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='8'
                              height='14'
                              viewBox='0 0 8 14'
                              fill='none'
                            >
                              <path
                                d='M1 13L6.29289 7.70711C6.62623 7.37377 6.79289 7.20711 6.79289 7C6.79289 6.79289 6.62623 6.62623 6.29289 6.29289L1 1'
                                stroke='black'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                            <span className='heading3 text-black'>{fe?.attributes?.title}</span>
                          </div>
                        ))}
                    </div>
                    <div className='mt-[25px]'>
                      <button
                        onClick={() =>
                          localStorage.getItem("studio") !== null
                            ? navigate(`/studio/${item?.attributes?.title?.toLowerCase().replace(" ", "-")}`, {
                                state: { id: item?.id },
                              })
                            : setModal(true)
                        }
                        className='h-[43px] lg:w-[247px] w-full rounded-[6px] bg-flexi uppercase subheading3'
                      >
                        go to studio
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={` ${
            isMap ? "opacity-0 h-0" : "opacity-100  mt-4"
          } h-[500px] overflow-y-scroll   gap-y-6 flex flex-col mx-5`}
        >
          {searchData?.map((item, idx) => (
            <div
              key={idx}
              className='p-4 rounded-[6px]  md:flex-row flex-col  gap-4 justify-between  bg-lilac flex items-center '
            >
              <div className='h-[155px] lg:w-[80%] w-full rounded-[6px] overflow-hidden'>
                <img src={item?.attributes?.background?.data?.attributes?.url} className='w-full h-full object-cover' />
              </div>
              <div className='flex  md:items-center items-start lg:flex-row flex-col lg:gap-0 gap-4 justify-between w-full'>
                <div className='flex flex-col  justify-between '>
                  <h2 className='subheading3 text-black mb-2 uppercase' style={{ fontSize: "18px" }}>
                    {item?.attributes?.title}
                  </h2>

                  <div
                    onClick={() =>
                      window.open(
                        `https://www.google.com/maps/search/?api=1&query=${item?.attributes?.location?.coordinates?.[1]},${item?.attributes?.location?.coordinates?.[0]}`,
                        "_blank"
                      )
                    }
                    className='flex cursor-pointer items-start justify-start'
                  >
                    <div className=' w-[30px]  mt-1'>
                      <img className='w-[15px]' src={location} />
                    </div>
                    <p style={{ fontSize: 16 }} className='heading3 text-black hover:underline md:w-[200px] w-full'>
                      {item?.attributes?.fullAddress}
                    </p>
                  </div>
                  <div className='flex  items-center flex-col '>
                    {item?.attributes?.studio_features?.data?.map((fe, idx) => (
                      <div key={idx} className='flex items-center gap-4  w-full'>
                        <div className='xxx:w-auto w-[15px] pl-1 '>
                          <svg xmlns='http://www.w3.org/2000/svg' width='8' height='14' viewBox='0 0 8 14' fill='none'>
                            <path
                              d='M1 13L6.29289 7.70711C6.62623 7.37377 6.79289 7.20711 6.79289 7C6.79289 6.79289 6.62623 6.62623 6.29289 6.29289L1 1'
                              stroke='black'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                        <span style={{ fontSize: 16 }} className='heading3 text-black lg:w-[190px] w-full'>
                          {fe?.attributes?.title}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='flex lg:flex-col flex-row gap-4 w-full justify-between  items-end'>
                  <button
                    onClick={() => handleJoinMembership(item?.attributes?.title)}
                    className='h-[43px] w-[150px]  transition-all rounded-[6px] bg-flexi border border-flexi hover:bg-transparent hover:border-black uppercase subheading3'
                  >
                    join now
                  </button>
                  <button
                    onClick={() => {onOpenFreePass();setSelectedStudio({city:item?.attributes?.title,state:item?.attributes?.state?.data?.attributes?.Name})}}
                    className='h-[43px] w-[150px]  transition-all rounded-[6px] bg-flexi border border-flexi hover:bg-transparent hover:border-black uppercase subheading3'
                  >
                    enquire now
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        </div>
  );
}

export default Studios;
