import React from "react";
import Layout from "../../components/layout/Layout";
import Banner from "../../components/studio/Banner";
import Active from "../../common/buttons/Active";
import GetYourFreePass from "../../components/GetYourFreePass";
import { Link } from "gatsby";
import Studios from "../../components/studio/Studios";
import useFetch from "../../hooks/useFetch";
import ErrorBoundary from "../../utils/ErrorBoundary";
import ShapeInfo from "../../assets/images/Shape/ShapeInfo";
function Studio() {
  const { data } = useFetch({
    params: "studio-page",
    populate:
      "banner1.background,banner1.mobileImage,banner2.background,banner3.background,banner4.background",
  });

  return (
    <ErrorBoundary>
      <Layout>
        <Banner data={data} />
        <Studios />
        {/* <div className="relative">
          <img
            className="lg:h-[680px] w-full h-[350px] object-cover"
            src={
              data?.data?.attributes?.banner2?.background?.data?.attributes?.url
            }
          /> */}
        {/* <div className="absolute top-1/2  left-1/2 z-10 lg:w-[1017px] w-[90%] flex justify-center items-center flex-col -translate-x-1/2  -translate-y-1/2   text-white">
            <h2 className="text-[#FEFEFF] heading text-center ">
              {data?.data?.attributes?.banner2?.title}
            </h2>
            <p className="my-[24px] heading3 text-center">
              {data?.data?.attributes?.banner2?.description}
            </p>
            <Link to='/own-a-studio'>
                            <Active text='own a studio' />
                        </Link>
          </div> */}
        {/* </div> */}
        <div className="bg-agate py-[76px] flex items-center justify-center flex-col mx-auto">
          <div
            className={`relative lg:w-[856px] md:w-[70%] w-[90%] mx-auto  overflow-hidden  `}
          >
            <ShapeInfo color="#C5C5FB" />
            <img
              className="absolute object-cover w-full h-full object-center top-0"
              src={
                data?.data?.attributes?.banner3?.background?.data?.attributes
                  ?.url
              }
            />
          </div>
          <div className="mt-[32px] flex flex-wrap items-center lg:gap-[100px] gap-[40px] justify-around">
            {data?.data?.attributes?.banner3?.customMetadata?.map(
              (item, idx) => (
                <div
                  key={idx}
                  className="flex items-center justify-center flex-col"
                >
                  <h1 className="heading text-black uppercase font-bold">
                    {item?.value}
                  </h1>
                  <span className="mt-[22px] uppercase text-black subheading3">
                    {item?.text}
                  </span>
                </div>
              )
            )}
          </div>
          <div className="flex items-center mt-[32px]">
            <Link to="/classes">
              <Active text="check our classes" />
            </Link>
          </div>
        </div>
        <GetYourFreePass
          color={"#FFEDB7"}
          title={data?.data?.attributes?.banner4?.title}
          desc={data?.data?.attributes?.banner4?.description}
          image={
            data?.data?.attributes?.banner4?.background?.data?.attributes?.url
          }
          vector={1}
        />
      </Layout>
    </ErrorBoundary>
  );
}

export default Studio;
