import React, { useCallback, useContext, useEffect, useState } from "react";

import { motion } from "framer-motion";
import { navigate } from "gatsby";

import { useLocation } from "@reach/router";

import { navBar } from "../../mock/nav";
import logo from "../assets/svg/logo.svg";
import Active from "../common/buttons/Active";
import { LocationContext } from "../context/LocationContext";
import { RegisterContext } from "../context/RegisterContext";
import Sidebar from "./mobile/Sidebar";
import CreateProfile from "./Modal/CreateProfile";
import FreePass from "./Modal/FreePass";
import ModalFindStudios from "./ModalFindStudio/ModalFindStudios";
import ModalFreePass from "./ModalFindStudio/ModalFreePass";

function Navbar() {
  const [states, setStates] = useState({
    list: ["ACT", "QLD", "NSW", "VIC"],
    selected: "",
  });
  const [modalSelectLocation, setOpen] = useState(false);

  const [modal, setModal] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState("");
  const [navLinks, setNavLinks] = useState<object>({
    list: ["classes", "memberships", "logo", "studios", "about"],
    active: "",
  });

  const [tab, setTab] = useState("sign in");
  const [modalSignin, setModalSignin] = useState<boolean>(false);
  const addPlan = useContext(RegisterContext).addPlan;
  const setAddPlan = useContext(RegisterContext).setAddPlan;
  const modalChangeLocation = useContext(LocationContext).setModal;

  const [modalFindStudio, setModalFindStudio] = useState<boolean>(false);
  const [modalFreePass, setModalFreePass] = useState(false);

  // Register modal
  const setGotoPlan = useContext(RegisterContext).setGotoPlan;
  const path = useLocation();

  useEffect(() => {
    setCurrentItem(window.location.pathname);
  }, []);

  // Check User has selected location? => and Navigate to timetable
  const handleGetTimeTable = () => {
    localStorage.getItem("studio") !== null
      ? navigate("/timetable")
      : setOpen(true);
  };

  // Approved the time table route if the user has selected location
  useEffect(() => {
    if (
      localStorage.getItem("studio") == null &&
      path.pathname == "/timetable"
    ) {
      navigate("/");
    }
  }, [path.pathname]);

  const handleGotoTimeTable = () => {
    window.localStorage.getItem("studio") && navigate("/timetable");
  };

  const handleAddPlan = () => {
    setModal(false);
    setAddPlan(true);
  };

  const handleCloseModalFreePass = useCallback(() => {
    setModalFindStudio(!modalFindStudio);
    setModalFreePass(!modalFreePass);
  }, []);

  return (
    <>
      <div
        style={{ zIndex: 999999999 }}
        className="fixed   z-50 w-full flex justify-center  border-b desktop:border-b-white border-b-transparent  lg:backdrop-blur-[6px] backdrop-blur-none desktop:bg-[rgba(0,0,0,0.2)]"
      >
        <div className=" py-[16px]  px-[70px] relative  w-max">
          <div className="desktop:flex hidden gap-[30px] items-center ">
            {navBar.map((i: any, idx) => (
              <div key={idx} className="relative">
                {i.type == "link" && (
                  <div
                    onClick={() => {
                      navLinks.list.includes(i.text) && navigate(i.link);
                      i.text == "timetable" && handleGetTimeTable();
                    }}
                    className="buttons hover:text-apricot cursor-pointer text-[14px] leading-[14px] tracking-[0.05em] text-white uppercase whitespace-nowrap"
                  >
                    {i.text}
                  </div>
                )}
                {i.type == "linkLogo" && idx == 4 && (
                  <div
                    onClick={() => {
                      navigate(i.link);
                    }}
                    className="shrink-0 cursor-pointer w-[335px] pr-6 flex justify-center"
                  >
                    <img src={logo} />
                  </div>
                )}
                {/* Join now + Login */}
                {i.type == "linkButton" && i.text == "log in" && (
                  <div
                    onClick={() => setModal(true)}
                    className="buttons rounded-[5px] cursor-pointer hover:text-black transition-all duration-300 hover:bg-transparent border-white hover:border-black text-white border w-[117px] h-[40px] flex items-center justify-center uppercase"
                  >
                    INTRO OFFER
                  </div>
                )}
                {i.type == "linkButton" && i.text == "join now" && (
                  <div
                    onClick={() => {
                      // handlleRegister();
                      setModalFindStudio(true);
                    }}
                    // onClick={() => path.pathname == '/memberships' ? handleScrollWithSamePage() : navigate('/memberships', { state: { isScroll: true } })}
                    className="buttons rounded-[5px] cursor-pointer border-flexi  hover:border-black  hover:bg-transparent w-[117px] h-[40px] border transition-all  flex items-center  justify-center uppercase bg-flexi text-black "
                  >
                    join now
                  </div>
                )}
                {/* Custom Motion - Active Tab */}
                {((i.type == "link" && i.link == currentItem) ||
                  (path.pathname.includes("/studio") && idx == 0) ||
                  (path.pathname.includes("/blog") && idx == 5) ||
                  (i.link !== "/" && idx == 4 && i.link == currentItem)) && (
                  <>
                    <motion.div
                      layoutId="underline"
                      style={{ height: "4px" }}
                      className={`absolute  left-0 bg-white ${
                        i.link == "/"
                          ? "ml-[103px] w-[207px] bottom-[-19px]"
                          : "w-full bottom-[-29px]"
                      }`}
                    ></motion.div>
                  </>
                )}
                {/* Modal Select Locations */}
                {i.text == "timetable" && modalSelectLocation && (
                  <div className="absolute px-3 -right-6 h-[165px] top-[42px] rounded-md  w-[200px] bg-turf">
                    <h5 className="text-white heading3 pb-1 mt-3 text-center border-b border-b-white">
                      Select your State
                    </h5>
                    <ul className="px-3 flex flex-col gap-[7px] mt-3">
                      {states.list.map((item) => (
                        <li
                          onClick={() => {
                            window.localStorage.setItem(
                              "studio",
                              JSON.stringify(item)
                            );
                            handleGotoTimeTable();
                          }}
                          onMouseEnter={() =>
                            setStates({ ...states, selected: item })
                          }
                          key={item}
                          className="flex justify-between cursor-pointer items-center"
                        >
                          <p
                            style={{ fontSize: 16 }}
                            className="heading3 text-white"
                          >
                            {item}
                          </p>
                          <div className="w-[18px] flex items-center justify-center h-[18px] border border-white rounded-[50%]">
                            {item == states.selected && (
                              <span className="w-[15px] h-[15px] rounded-[50%] bg-white"></span>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="desktop:hidden min-w-full absolute top-0  block">
          <Sidebar />
        </div>

        {/* Modal Freepass */}
        {modal && <FreePass setModal={setModal} />}

        {/* MODAL FIND STUDIOS */}
        {modalFindStudio && !modalFreePass && (
          <ModalFindStudios
            onOpenFreePass={handleCloseModalFreePass}
            setModal={setModalFindStudio}
          />
        )}
        {modalFreePass && (
          <ModalFreePass
            onClose={handleCloseModalFreePass}
            setModal={setModalFreePass}
          />
        )}
      </div>
      {/* Modal Signin  */}
      {modalSignin && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .4)", zIndex: 999999999 }}
          className="fixed w-full  h-full z-40 left-0 top-0  border border-gray  rounded-md"
        >
          <div
            style={{ zIndex: 99999999999 }}
            className="relative z-50 lg:w-[850px] w-full h-[90vh]  bg-white left-1/2 transformt rounded-md -translate-x-1/2 -translate-y-1/2 top-1/2"
          >
            <div className="pt-16 px-10 md:flex hidden mx-auto  items-center justify-between">
              <CreateProfile />
              <div className="-mt-12 ">
                <h2 style={{ fontSize: 30 }} className="heading3 text-center">
                  Create an account
                </h2>
                <p className="heading3 text-center mb-[30px]">
                  Please create an account to continue
                </p>
                <div
                  onClick={handleAddPlan}
                  className="mx-auto w-full flex justify-center"
                >
                  <Active text="sign up" />
                </div>
              </div>
            </div>
            {/* Mobile */}
            <div className="md:hidden block">
              <div className="flex flex-col  pt-[50px]">
                <div className="flex items-start">
                  <div onClick={() => setTab("sign in")} className="ml-2">
                    <Active text="sign in" />
                  </div>
                  <div onClick={() => setTab("sign up")} className="ml-5">
                    <Active text="sign up" />
                  </div>
                </div>
                <div className="mt-[20px]">
                  {tab == "sign in" ? (
                    <CreateProfile />
                  ) : (
                    <div className="min-h-full mt-5">
                      <h2
                        style={{ fontSize: 30 }}
                        className="heading3 text-center"
                      >
                        Create an account
                      </h2>
                      <p className="heading3 text-center mb-[30px]">
                        Please create an account to continue
                      </p>
                      <div
                        onClick={handleAddPlan}
                        className="mx-auto w-full flex justify-center"
                      >
                        <Active text="sign up" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              onClick={() => setModalSignin(false)}
              style={{ zIndex: 999999999999999 }}
              className="absolute cursor-pointer right-4 top-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19 5L5 19"
                  stroke="#545459"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5 5L19 19"
                  stroke="#545459"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
