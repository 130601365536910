import React, { useState } from "react";
import Layout from "../components/layout/Layout";
import HeroBanner from "../components/HomePage/HeroBanner";
import TextAnimation from "../components/own-a-studio/TextAnimation";
import MoreThanWorkout from "../components/about/MoreThanWorkout";
import ShareInteresting from "../components/about/ShareInteresting";
import YogaExp from "../components/about/YogaExp";
import { Link } from 'gatsby'
import Blog from "../components/about/Blog";
import useFetch from "../hooks/useFetch";
import FreePassImgShape from "../assets/images/Shape/FreePassImgShape";
import Active from "../common/buttons/Active";
import ErrorBoundary from "../utils/ErrorBoundary";
function about() {
  const [tabMoused, setTabMoused] = useState<string>('')
  const { data: classes } = useFetch({ params: 'class-categories', populate: 'image,shape' })
  const { data } = useFetch({ params: 'about-page', populate: 'banner1.background,banner1.mobileImage,banner2.background,banner3.background,banner4.background,introduce' })

  return (
    <ErrorBoundary>
      <Layout>
        <HeroBanner
          img={data?.data?.attributes?.banner1?.background?.data?.attributes?.url}
          bannerMobile={data?.data?.attributes?.banner1?.mobileImage?.data?.attributes?.url}
          title={data?.data?.attributes?.banner1?.title} />
        <TextAnimation />
        <MoreThanWorkout data={data} />
        <ShareInteresting data={data} />
        <YogaExp data={data} />
        <p className="py-[90px] heading text-center mx-auto desktop:w-[1270px] w-[80%]">
          {data?.data?.attributes?.classTitle}
        </p>
        <div className="grid lg:grid-cols-4 grid-cols-2">
          {classes?.data?.map((item: object, idx: number) => (
            <Link key={idx} state={{ classSelected: item?.attributes?.name }} to='/classes'>
              <div onMouseEnter={() => setTabMoused(item?.attributes?.name)}
                className='cursor-pointer transition-all relative h-auto '>
                <img className='w-full ' src={item?.attributes?.image?.data?.attributes?.url} />
                {tabMoused == item?.attributes?.name && (
                  <img className='absolute top-0 left-0 w-full '
                    src={item?.attributes?.shape?.data?.attributes?.url}
                  />
                )}
                <div style={{ backgroundColor: item?.attributes?.color?.color }} className={` w-full   flex items-center feature justify-center text-white lg:h-[116px] h-[90px]`}>
                  <span>
                    {item?.attributes?.name}
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="lg:py-[94px] py-[50px] flex xxx:flex-row flex-col justify-center items-center lg:gap-[200px] gap-10 bg-agate">
          <div
            className={`relative lg:w-[475px] w-[80%]`}>
            <FreePassImgShape color={"#BBCCF3"} />
            <img className="absolute w-full h-[calc(100%-3px)] top-[1px]" src={data?.data?.attributes?.banner4?.background?.data?.attributes?.url} />
          </div>
          <div className="lg:w-[460px]  w-[90%]">
            <h1 className="heading">{data?.data?.attributes?.banner4?.title}</h1>
            <p className="heading3 my-[48px]">{data?.data?.attributes?.banner4?.description}</p>
            <div >
              {/* <Active text="go to section" /> */}
            </div>
          </div>
        </div>
        {/* <Blog /> */}
      </Layout>
    </ErrorBoundary>
  );
}

export default about;
