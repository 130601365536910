import React, {
  useEffect,
  useState,
} from 'react';

import { Link } from 'gatsby';

import shared from '../../assets/images/membership/shared_box.png';
import FreePassShape from '../../assets/images/Shape/FreePassShape';
import ShapeInfo from '../../assets/images/Shape/ShapeInfo';
import Active from '../../common/buttons/Active';
import DropdownBase from '../../common/dropdowns/DropdownBase';
import InputBase from '../../common/inputs/RebalanceInputBase';
import useFetch from '../../hooks/useFetch';
import {
  FreePassToStrapiAPI,
  GetFreePassAPI,
} from '../../utils/FetchData';
import Succes from '../Modal/Succes';

function Flexible(props) {
  const { isShared, title, description, background, sharedTitle, sharedDesc, freePassTitle, freePassDesc, backgroundFreePass, textBtn, isShowBtn } = props
  const [status, setStatus] = useState<boolean>(false)

  const { data: locations } = useFetch({ params: 'studios', populate: 'state' })


  const [userInfo, setUserInfo] = useState({
    venue_id: "1234",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    interested_in: "Fitness, Personal Training",
    user_id: "1234",
    referred_by: "Friend",
    venue_name: "",
    "Source Group": "Website",
    "Source Name": "Free Class",

  })

  const handleChangeValue = (e) => {
    const value = e.target.value
    setUserInfo((prevState) => ({ ...prevState, [e.target.name]: value }))
  }

  const handleSubmit = async () => {
    const emptyFields = Object.entries(userInfo).filter(([key, value]) => value === '');

    if (emptyFields.length > 0) {
      // If any fields are empty, alert the user with the name of the empty field
      const fieldNames = emptyFields.map(([key, value]) => key).join(', ');
      alert(`The following fields are required: ${fieldNames}`);
    } else {
      await GetFreePassAPI(userInfo);
      await FreePassToStrapiAPI(userInfo)
      setStatus(true)
    }
  }

  // Get state from studio
  useEffect(() => {
    let state = locations?.data?.filter(item => item?.attributes?.title == userInfo.city)?.[0]?.attributes?.state?.data?.attributes?.Name
    setUserInfo({ ...userInfo, state: state, venue_name: userInfo.city })
  }, [userInfo.city])

  // Cleanup Data after submit
  useEffect(() => {
    if (!status) {
      setUserInfo({
        venue_id: "1234", first_name: "", last_name: "", email: "", phone: "", city: "", state: "", interested_in: "Fitness, Personal Training", user_id: "1234", referred_by: "Friend", venue_name: "", "Source Group": "Website",
        "Source Name": "Free Class",
      })
    }
  }, [status])


  return (
    <div
      style={{ backgroundColor: props.flexBackground }}
      className=" bg-creme lg:pt-[80px] pt-[50px] mx-auto"
    >
      <div className="lg:pb-[80px] pb-[50px] ">
        <h2 className="text-black heading text-center lg:w-[569px] w-[90%] mx-auto">
          {title}
        </h2>
        {/* Flexible total classes container */}
        <div className="mt-[72px] lg:flex-row flex-col flex items-center justify-center lg:gap-[72px] gap-7 ">
          <div className="flex lg:flex-col flex-row lg:gap-11 gap-4">
            {description?.slice(0, 3)?.map((item, idx) => (
              <div key={idx} className="flex items-center justify-center flex-col">
                <h2 className="heading text-black">{item?.value}</h2>
                <span className="text-black uppercase mt-3 lg:text-[18px] text-[12px]">
                  {item?.text}
                </span>
              </div>
            ))}

          </div>
          <div >
            <div
              className={`relative lg:w-[547px] sm:w-[425px] w-[300px] h- mx-auto  overflow-hidden  `}>
              <ShapeInfo isSmall={true} color="#C5C5FB" />
              <img className="absolute object-cover w-full h-full object-bottom top-0" src={background} />
            </div>
            {isShowBtn && (
              <div className="mx-auto flex justify-center mt-[48px]">
                <Link to={'/memberships'}>
                  <Active text={textBtn} />
                </Link>
              </div>
            )}
          </div>
          <div className="md:flex hidden lg:flex-col flex-row lg:gap-11 gap-4">
            {description?.slice(3, 6)?.map((item, idx) => (
              <div key={idx} className="flex items-center justify-center flex-col">
                <h2 className="heading text-black">{item?.value}</h2>
                <span className="text-black uppercase mt-3 lg:text-[18px] text-[12px]">
                  {item?.text}
                </span>
              </div>
            ))}
          </div>
        </div>


      </div>
      {/* Shared box */}
      {isShared == true && (
        <>
          <div className="relative" >
            <img className="lg:h-[680px] h-[420px] w-full object-cover" src={shared} />
            <div className="absolute top-1/2  left-1/2 z-10 lg:w-[920px] w-[90%] flex justify-center items-center flex-col -translate-x-1/2  -translate-y-1/2   text-white">
              <h2 className="text-[#FEFEFF] heading text-center ">
                {sharedTitle}
              </h2>
              <p className="my-[24px] heading3 text-center">
                {sharedDesc}
              </p>
              <Link to="/classes">
                <Active text="check our classes" />
              </Link>
            </div>
          </div>
          <div className="w-full bg-lemonadeSecondary">
            <div className='lg:h-[600px] h-auto bg-lemonadeSecondary flex lg:flex-row flex-col items-center justify-between lg:py-[89px] py-[40px] lg:pl-[89px] pl-6 lg:pr-[49px] pr-6 max-w-[1440px] mx-auto'>
              <div className="lg:w-auto md:w-[70%] w-full">
                <div
                  className={`relative lg:w-[412px] md:w-[70%] w-[100%]`}>
                  <FreePassShape color={'#FFEDB7'} />
                  <img alt="background free pass" className="absolute object-cover w-full h-[calc(100%-3px)] top-[1px]" src={backgroundFreePass} />
                </div>
              </div>
              <div className='lg:w-[814px] lg:ml-11 ml-0 lg:mt-0 mt-7 w-full'>
                <h2 className='heading text-black sm:mt-0 mt-4'>{freePassTitle}</h2>
                <p className='mt-[18px] text-black heading3 '>{freePassDesc}</p>
                <div className="grid md:grid-cols-2 grid-cols-1 col-span-5 mt-6 gap-x-3 gap-y-6">
                  <InputBase value={userInfo.first_name} onChange={handleChangeValue} nameInput="first_name" holder="First Name*" />
                  <InputBase value={userInfo.last_name} onChange={handleChangeValue} nameInput="last_name" holder="Last name*" />
                  <InputBase value={userInfo.email} onChange={handleChangeValue} nameInput="email" holder="Email address*" />
                  <InputBase value={userInfo.phone} onChange={handleChangeValue} nameInput="phone" holder="Phone Number*" />
                  <DropdownBase color='black' holder='Location*'
                    options={locations?.data}
                    location={userInfo.city}
                    setLocation={selectedLocation => setUserInfo({ ...userInfo, city: selectedLocation })} />
                  <div className="lg:col-span-2 col-span-1 mt-5   flex justify-end">
                    <div onClick={handleSubmit}>
                      <Active text="ENQUIRE NOW" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </>
      )}
      {status && <Succes open={status} setOpen={setStatus} />}
    </div>
  );
}

export default Flexible;
