import React from 'react';

interface IProps {
  nameInput: string,
  onChange: () => void
}
function RebalanceInputBase(props: IProps) {
  const { nameInput, onChange, value } = props
  return props.type ? (
    <textarea
      name={nameInput}
      style={{
        width: props.width ? props.width : "100%",
        height: props.height ? props.height : "100%",
      }}
      value={value}
      onChange={onChange}
      className={`rounded-[5px] bg-transparent border  py-[9.5px] px-[12px] body outline-none ${props.isWhite
        ? "placeholder:text-white text-white border-white"
        : "placeholder:text-black text-black border-black"
        } `}
      placeholder={props.holder}
    />
  ) : (
    <input
      style={{
        width: props.width ? props.width : "100%",
        // height: props.height ? props.height : "100%",
      }}
      name={nameInput}
      value={value}
      onChange={onChange}
      className={`rounded-[5px] bg-transparent border  py-[9.5px] px-[12px] body outline-none ${props.isWhite
        ? "placeholder:text-white text-white border-white"
        : "placeholder:text-black text-black border-black"
        } `}
      placeholder={props.holder}
    />
  );
}

export default RebalanceInputBase;
